// @ts-nocheck
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @next/next/no-img-element */

import { background_list } from "@/assets/images"
import Image from "next/image"

// @ts-nocheck
const CenteredRadiantGradient = ({className}:any) => {
  return (
    <div className={`tw-absolute tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center ${className}`}>
      <Image src={background_list.gradient_centered_orange} alt="" className="tw-w-[100%] tw-h-[100%]" />
    </div>
  )
}

export default CenteredRadiantGradient
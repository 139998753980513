/* eslint-disable @next/next/no-img-element */
/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import Link from 'next/link'
import Image from 'next/image'
import { imageStatic } from '@/utility/helper/image'
import { StyledButton, SwitchLanguage } from '@/components/atoms'
import { background_list, icon_list } from '@/assets/images'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Fragment, useContext, useEffect, useState } from 'react'
import { AiOutlineBars, AiOutlineClose, AiOutlineDown, AiOutlineRight } from 'react-icons/ai'
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import route_list from '@/constant/routeUrl'
import { getUserDataRawLS, getUserDetailData } from '@/utility/helper/generalHandler'
import _ from 'lodash'
import { getCookie, removeCookie, setCookie } from '@/utility/helper/cookies'
import DataHandlerContext from '@/utility/context/dataHandler'

function classNames(...classes: any) {
	return classes.filter(Boolean).join(' ')
}

const NavbarApp = () => {
  const {language, token, role, dataUser, dispatch}:any = useContext(DataHandlerContext)
	const router = useRouter()
  const [enabled, setEnabled] = useState('id')
	const { t, i18n } = useTranslation('navbar')
	const userDetail = dataUser
	const navigation = [
		{
			name: t('navbar.menu.services.mainmenu'),
			child: [
				{
					label: t('navbar.menu.services.submenu1'),
					href: route_list.social_media_management,
				},
				{
					label: t('navbar.menu.services.submenu2'),
					href: route_list.live_shopping,
				},
				{
					label: t('navbar.menu.services.submenu3'),
					href: route_list.endorsement,
				},
			],
			current: true,
		},
		{
			name: t('navbar.menu.partners.mainmenu'),
			child: [
				{
					label: t('navbar.menu.partners.submenu1'),
					href: route_list.content_creator,
				},
				{
					label: t('navbar.menu.partners.submenu2'),
					href: route_list.live_streamer,
				},
				{
					label: t('navbar.menu.partners.submenu3'),
					href: route_list.influencer,
				},
			],
			current: false,
		},
		{
			name:  t('navbar.menu.learn.mainmenu'),
			child: [
				{
					label: t('navbar.menu.learn.submenu1'),
					href: route_list.about_us,
				},
				{
					label: t('navbar.menu.learn.submenu2'),
					href: route_list.agenda,
				},
				{
					label: t('navbar.menu.learn.submenu3'),
					href: route_list.press,
				},
				{
					label: t('navbar.menu.learn.submenu4'),
					href: route_list.our_team,
				},
				{
					label: t('navbar.menu.learn.submenu5'),
					href: route_list.career,
				},
				{
					label: t('navbar.menu.learn.submenu7'),
					href: route_list.blog,
				},
				{
					label: t('navbar.menu.learn.submenu6'),
					href: route_list.help_center,
				},
			],
			current: false,
		},
	]
	const [userMenu, setUserMenu] = useState([
		{
			title: t('navbar.profile.menu1'),
			onClick: () => router.push(route_list.userProfile)
		},
		{
			title: t('navbar.profile.menu2'),
			onClick: () => router.push(route_list.userTransaction)
		},
		{
			title: t('navbar.profile.menu3'),
			onClick: () => {
				dispatch({ type: "SetUserDataRawLS", data: {} })
				dispatch({ type: "SetDetailData", dataUser: {} })
				removeCookie("user-data-detail")
				removeCookie("user-data-raw")
				setTimeout(() => {
					router.push(route_list.home)
				}, 500);
			}
		},
	])

	useEffect(() => {
		if(role === 'partner') {
			const cloneUserMenu = _.cloneDeep(userMenu)
			cloneUserMenu.splice(0,2)
			setUserMenu(cloneUserMenu)
		}
	}, [role])

	useEffect(() => {
		if(!_.isEmpty(language)) {
			setEnabled(language === 'en')
		}
	}, [language])

	const onToggleLanguageClick = (e:any) => {
		setEnabled(e)
		const changeTo = getCookie('language') === 'en' ? 'id' : 'en';
		const cloneUserMenu = _.cloneDeep(userMenu)
		cloneUserMenu.forEach((result, index) => {
			result.title = t(`navbar.profile.menu${index+1}`)
		})

		i18n.changeLanguage(changeTo);
		setUserMenu(cloneUserMenu)
		setCookie("language", changeTo, 3)
		dispatch({type: "SetLanguage", language: changeTo})
    // const { pathname, asPath, query } = router
    // router.push({ pathname, query }, asPath, { locale: changeTo })
  }

	// useEffect(() => {
	// 	const changeTo = getCookie('language') === 'en' ? true : false;
	// 	setEnabled(changeTo)
	// }, [])

	return (
		<Disclosure
			as='nav'
			className='tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-py-4'
		>
			{({ open }) => (
				<>
					<div className='tw-mx-auto tw-max-w-7xl tw-px-2 sm:tw-px-6 lg:tw-px-8'>
						<div className='tw-relative tw-flex tw-h-16 tw-items-center tw-justify-between'>
							<div className=' tw-inset-y-0 tw-left-0 tw-flex tw-items-center sm:tw-hidden'>
								{/* Mobile menu button*/}
								<Disclosure.Button className='tw-relative tw-inline-flex tw-items-center tw-justify-center tw-rounded-md tw-p-2 tw-text-gray-400 tw-hover:bg-gray-700 tw-hover:text-white tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-inset tw-focus:ring-white'>
									<span className='tw--inset-0.5' />
									<span className='tw-sr-only'>Open main menu</span>
									{open ? (
										<AiOutlineClose
											className='tw-block tw-h-6 tw-w-6 tw-text-light-cream'
											aria-hidden='true'
										/>
									) : (
										<AiOutlineBars
											className='tw-block tw-h-6 tw-w-6 tw-text-light-cream'
											aria-hidden='true'
										/>
									)}
								</Disclosure.Button>
							</div>
							<div className='tw-flex tw-flex-1 tw-items-center  sm:tw-items-stretch sm:tw-justify-start'>
								<button className='tw-flex tw-flex-shrink-0 tw-items-center' onClick={() => router.push('/')} id='sb-logo-nav' aria-label="sb-logo-nav">
									<Image
										alt=''
										src={background_list.sosbread_white_logo}
										className='tw-cursor-pointer md:tw-h-8 tw-h-5 tw-w-auto'
									/>
								</button>
								<div className='tw-hidden sm:tw-ml-6 sm:tw-block'>
									<div className='tw-flex tw-space-x-4'>
										{navigation.map((item, index) => (
											<Menu
												as='div'
												className='tw-relative tw-ml-3'
												key={`menu-${index}`}
											>
												<div>
													<Menu.Button className='tw-rounded-md tw-px-3 tw-py-2 tw-text-md tw-font-medium hover:tw-text-light-cream tw-text-white'>
														{item.name}
													</Menu.Button>
												</div>
												<Transition
													as={Fragment}
													enter='tw-transition ease-out tw-duration-100'
													enterFrom='tw-transform tw-opacity-0 tw-scale-95'
													enterTo='tw-transform tw-opacity-100 tw-scale-100'
													leave='tw-transition ease-in tw-duration-75'
													leaveFrom='tw-transform tw-opacity-100 tw-scale-100'
													leaveTo='tw-transform tw-opacity-0 tw-scale-95'
												>
													<Menu.Items className='tw-absolute tw-left-[13px] tw-z-10 tw-mt-2 tw-origin-top-left tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-w-max '>
														{item.child.map((child, index) => {
															return (
																<Menu.Item key={`child-menu-${index}`}>
																	{({ active }) => (
																		<button
																			// href={child.href}
																			onClick={() => router.push(child.href, undefined, { shallow: true })}
																			className={classNames(
																				active ? 'tw-bg-gray-100' : '',
																				'tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-dark-green tw-text-md tw-font-semibold tw-w-full tw-text-left'
																			)}
																		>
																			{child.label}
																		</button>
																	)}
																</Menu.Item>
															)
														})}
													</Menu.Items>
												</Transition>
											</Menu>
										))}
									</div>
								</div>
							</div>
							<div className='tw-absolute tw-inset-y-0 tw-right-0 tw-items-center tw-pr-2 sm:tw-static sm:tw-inset-auto sm:tw-ml-6 sm:tw-pr-0 tw-gap-3 tw-flex'>
								{!_.isEmpty(userDetail) && (
									<div className=' tw-flex tw-flex-row tw-gap-5'>
										{/* <StyledButton 
											className=""
											title={<Image src={icon_list.notif_icon} alt='' width={20} height={20} />}
											onClick={() => router.push(route_list.userNotification)}
										/> */}
										<Menu as='div'>
											<Menu.Button className='tw-text-white tw-font-semibold md:tw-text-md tw-text-xs tw-items-center md:tw-flex tw-gap-2 tw-flex'>
												<div className=' tw-rounded-full tw-overflow-hidden tw-h-8 tw-w-8'>
													<img
														alt=''
														src={!_.isEmpty(userDetail.profile_picture) ? userDetail.profile_picture : "https://www.shutterstock.com/image-vector/vector-flat-illustration-grayscale-avatar-600nw-2281862025.jpg"}
														className='tw-cursor-pointer tw-w-full tw-h-full tw-bg-gray-200 tw-object-cover'
													/>
												</div>
												<span className='tw-capitalize'>{userDetail.first_name}</span>
												<AiOutlineDown />
											</Menu.Button>
											<Transition
												as={Fragment}
												enter='tw-transition ease-out tw-duration-100'
												enterFrom='tw-transform tw-opacity-0 tw-scale-95'
												enterTo='tw-transform tw-opacity-100 tw-scale-100'
												leave='tw-transition ease-in tw-duration-75'
												leaveFrom='tw-transform tw-opacity-100 tw-scale-100'
												leaveTo='tw-transform tw-opacity-0 tw-scale-95'
											>
												<Menu.Items className='tw-absolute tw-z-10 tw-mt-2 tw-origin-top-left tw-rounded-md tw-bg-white tw-py-1 tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none tw-w-max '>
													{userMenu.map((result, inx) => {
														return (
															<Menu.Item key={`child-menu-profile-${inx}`}>
																{({ active }) => (
																	<button
																		onClick={result.onClick}
																		className={classNames(
																			active ? 'tw-bg-gray-100' : '', 'tw-block tw-px-4 tw-py-2 tw-text-sm tw-text-dark-green tw-text-md tw-w-full tw-text-left'
																		)}
																	>
																		{result.title}
																	</button>
																)}
															</Menu.Item>
														)
													})}
												</Menu.Items>
											</Transition>
										</Menu>
									</div>
								)}
								{_.isEmpty(userDetail) && (
									<div className='tw-flex tw-gap-3'>
										<StyledButton
											title={t('navbar.login')}
											className='tw-text-light-cream tw-font-semibold md:tw-text-md tw-text-xs tw-items-center tw-border-2 tw-border-light-cream md:tw-px-12 tw-px-3 md:tw-py-2 tw-py-1 tw-rounded-full md:tw-flex '
											onClick={() => router.push(route_list.login)}
										/>
										<StyledButton
											title={t('navbar.register')}
											className='btn-dark-to-light-green-gradient tw-text-white tw-font-semibold md:tw-text-md tw-text-xs tw-items-center md:tw-px-12  tw-px-3 md:tw-py-2 tw-py-1 tw-rounded-full md:tw-flex '
											onClick={() => router.push(route_list.register)}
										/>
									</div>
								)}
								<SwitchLanguage handleOnChange={onToggleLanguageClick} isEnable={enabled} />
							</div>
						</div>
					</div>

					<Disclosure.Panel className='tw-sm:hidden'>
            <div className='tw-space-y-1 tw-px-2 tw-pb-3 tw-pt-2'>
              <Accordion allowZeroExpanded >
                {navigation.map((item, index) => {
                  return (
                    <AccordionItem key={`mobile-item-${index}`} className='p-0'>
                      <AccordionItemHeading className='tw-mb-0'>
                        <AccordionItemButton className='tw-flex tw-justify-between tw-py-3 tw-text-white tw-items-center'>
                          <div>{item.name}</div>
                          <AccordionItemState>
                            {({ expanded }) => ( <AiOutlineRight className={expanded ? 'tw-rotate-[90deg]' : 'tw-rotate-[-90deg]'} width={20} aria-hidden='true'/> )}
                          </AccordionItemState>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className='tw-p-2 tw-text-white tw-mt-0'>
                        {item.child.map((child, index) => {
                          return (
                            <button key={`child-m-menu-${index}`} className="tw-w-full tw-text-start hover:tw-bg-green-700 tw-rounded-md">
                              <a
                                href={child.href}
                                className="tw-block tw-pl-2 tw-py-2 tw-text-sm tw-text-md tw-font-semibold"
                              >
                                {child.label}
                              </a>
                            </button>
                          )
                        })}
                      </AccordionItemPanel>
                    </AccordionItem>
                  )
                })}
              </Accordion>
						</div>
					</Disclosure.Panel>
				</>
			)}
		</Disclosure>
	)
}

export default NavbarApp
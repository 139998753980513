'use-client'
import React from 'react';

type StyledButtonProps = {
  onClick: () => void;
  title?: string;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
}

const generateNumberId = (length: number) => {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const charactersLength = characters.length;
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

const StyledButton: React.FC<StyledButtonProps> = ({ onClick, title, children, className, disabled }) => {
  const id = title?.toLowerCase().split(' ').join('-');
  return (
    <button aria-label={title || 'button'} id={`${generateNumberId(5)}-${id}`} onClick={() => !disabled && onClick()} disabled={disabled} className={`tw-cursor-pointer ${className} ${disabled && 'tw-bg-gray-300'}`}>
      {title}
      {children}
    </button>
  )
}

StyledButton.defaultProps = {
  onClick: () => {},
  title: '',
  className: 'tw-px-4 tw-py-2 tw-text-white tw-rounded-md tw-mt-2 tw-bg-green-500'
}

export default StyledButton;
import React from 'react'
import PropTypes from 'prop-types'

type TextInputType = {
	data: {
		placeholder: string
		type?: string
		value: string
		disabled?: boolean
		name: string,
    rightAddon?: React.ReactNode;
		className?: string
	}
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const TextInput = ({ data, onChange }: TextInputType) => {
	return (
		<div className='tw-flex tw-flex-row tw-gap-3 tw-w-full'>
			<input
				type={data.type}
				name={data.name ? data.name : ''}
				placeholder={data.placeholder}
				autoComplete='off'
				className={`tw-w-full tw-text-black tw-border-[1px] tw-border-light-green tw-rounded-xl tw-px-3 tw-py-2 focus:tw-outline-none disabled:tw-bg-[#d1d1d16b] ${data.className}`}
				onChange={onChange}
				value={`${data.value}`}
				disabled={data.disabled}
			/>
			{data.rightAddon}
		</div>
	)
}

TextInput.propTypes = {
	data: PropTypes.object,
}

TextInput.defaultProps = {
	data: {
		title: '',
		placeholder: '',
		type: '',
		onChange: () => {},
		value: '',
		disabled: false,
    rightAddon: <div/>
	},
}

export default TextInput

/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
// @ts-nocheck
import { background_list } from "@/assets/images"
import { CardColList, CenteredRadiantGradient } from "@/components/atoms"
import Image from "next/image"

const TitleWantToBeCreator = ({data}:any) => {
  return (
    <div className='tw-m-auto tw-mb-20 tw-grid md:tw-grid-cols-2 tw-gap-20'>
      <div className='tw-w-full tw-overflow-hidden md:tw-bg-transparent tw-rounded-lg tw-mb-10'>
        <div className="md:tw-bg-transparent tw-bg-white tw-mb-10">
          <div className='d:tw-px-0 tw-px-5 md:tw-pt-0 tw-pt-5'>
            <h1 className='md:tw-text-5xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-90% tw-text-left tw-w-fit'>{data.title}</h1>
          </div>
          <div className='tw-w-full tw-z-10 tw-flex tw-flex-col md:tw-col-span-3  tw-p-5 tw-rounded-lg'>
            <p className='tw-max-w-3xl tw-text-left md:tw-pr-20'>
              {data.description}
            </p>
          </div>
        </div>
        <div className=" tw-flex tw-items-center tw-justify-center">
          <Image src={background_list.want_tobe_creator} alt="" className=" tw-w-[200px]" />
        </div>
      </div>
      <div className='tw-w-full tw-grid md:tw-grid-cols-2 tw-rounded-lg tw-gap-5 tw-relative'>
        <CenteredRadiantGradient />
        {data.points.map((item:any, index:number) => {
          return (
            <div className="tw-z-[1]" key={`ccl-${index}`}>
              <h5 className="md:tw-text-4xl tw-text-lg tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-mb-3 tw-w-fit">
                {item.title}
              </h5>
              <div className='tw-rounded-2xl tw-text-left tw-p-4 tw-bg-white md:tw-h-[70%] '>
                {item.description}
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TitleWantToBeCreator
// @ts-nocheck
'use-client'
import { getLanguage } from "@/utility/helper/generalHandler"
import StyledButton from "../button"
import { useContext } from "react"
import DataHandlerContext from "@/utility/context/dataHandler"

const GeneralEmptyData = () => {
  const {language}:any = useContext(DataHandlerContext)

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
      <div className=' '>{language === 'en' ? "Data is Empty" : "Data tidak di temukan"}</div>
      <StyledButton
        title="Refresh"
        className="btn-dark-to-light-green-gradient tw-text-white tw-font-semibold md:tw-text-md tw-text-xs tw-items-center  tw-px-5 md:tw-py-2 tw-py-1 tw-rounded-full md:tw-flex tw-mt-3"
        onClick={() => location.reload()}
      />
    </div>
  )
}

export default GeneralEmptyData
// @ts-nocheck
import { background_list } from "@/assets/images"
import Image from "next/image"

/* eslint-disable @next/next/no-img-element */
const CardEndorsWhatis = ({data}:any) => {
  return (
    <div className='tw-grid md:tw-grid-cols-2 md:tw-bg-transparent tw-bg-white tw-rounded-xl'>
      <div className='tw-w-full tw-flex tw-flex-col tw-gap-9 md:tw-bg-transparent tw-rounded-xl tw-overflow-hidden '>
        <div className="tw-w-full md:tw-p-10 tw-p-5 tw-mb-0 md:tw-bg-white tw-rounded-xl">
          <h2 className="tw-mb-4 md:tw-text-4xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-w-fit tw-text-left">
            {data[0].title}
          </h2>
            <p className="tw-text-base tw-leading-7 tw-black" dangerouslySetInnerHTML={{__html: data[0].description}}></p>
        </div>
        <div className="tw-w-full md:tw-p-10 tw-p-5 tw-mb-0 md:tw-bg-white tw-rounded-xl">
          <h2 className="tw-mb-4 md:tw-text-4xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-w-fit tw-text-left">
            {data[1].title}
          </h2>
            <p className="tw-text-base tw-leading-7 tw-black" dangerouslySetInnerHTML={{__html: data[1].description}}></p>
        </div>
      </div>
      <div className='tw-w-full tw-flex md:tw-h-[655px] tw-justify-end tw-overflow-hidden tw-rounded-xl md:tw-p-0 tw-p-5 '>
        <Image src={background_list.endorsementbgtop} alt="" />
      </div>
    </div>
  )
}

export default CardEndorsWhatis
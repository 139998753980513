import _ from 'lodash'
import type {
	InitialStateCheckboxType,
} from './types'

const handleChangeField = (event: any, initialSate: any) => {
	const clone_forms = _.cloneDeep(initialSate)
	const event_name = event.target.name
	const event_value = event.target.value

	if (_.isArray(clone_forms)) {
		let filteredForm = _.filter(
			clone_forms,
			(data) => data.name === event_name
		)[0]
		filteredForm.value = event_value
	} else {
		clone_forms.value = event_value
	}

	return clone_forms
}

const handleChangeSelect = (event: any, initialSate: any) => {
	const clone_forms = _.cloneDeep(initialSate)
	const event_name = event.target.name

	if (_.isArray(clone_forms)) {
		let filteredForm = _.filter(
			clone_forms,
			(data) => data.name === event_name
		)[0]
		filteredForm.value = event
	} else {
		clone_forms.value = event
	}

	return clone_forms
}

const handleChangeMultipleSelect = (event: any, initialSate: any) => {
	const clone_forms = _.cloneDeep(initialSate)
	let clone_event = _.cloneDeep(event)
	const event_name = event.target.name
	
	if (_.isArray(clone_forms)) {
		let filteredForm = _.filter(clone_forms,(data) => data.name === event_name)[0]
		delete clone_event['target']
		const newArray = Object.values(clone_event);

		filteredForm.value = newArray
	} else {
		// belum ada case yang membutuhkan
		// clone_forms.value = event
	}

	return clone_forms
}

const handleChangeCheckbox = (
	id: number,
	initialSate: InitialStateCheckboxType
) => {
	const clone_forms = _.cloneDeep(initialSate)
	let filteredForm = _.filter(clone_forms, (data) => data.id === id)[0]
	filteredForm.is_checked = !filteredForm.is_checked
	return clone_forms
}

const handleChangeFile = (event: any, initialSate: any) => {
	const clone_forms = _.cloneDeep(initialSate)
	const event_name = event.target.name
	const event_value = event.target.files[0]

	if (_.isArray(clone_forms)) {
		let filteredForm = _.filter(
			clone_forms,
			(data) => data.name === event_name
		)[0]
		filteredForm.value = event_value
	} else {
		clone_forms.value = event_value
	}

	return clone_forms
}

const handleChangeTimeRange = (event: any, initialSate: any) => {
	const clone_forms = _.cloneDeep(initialSate)
	const event_name = event.target.name
	const start_date = event.target.startDate
	const end_date = event.target.endDate

	if (_.isArray(clone_forms)) {
		let filteredForm = _.filter(
			clone_forms,
			(data) => data.name === event_name
		)[0]
		filteredForm.startDate = start_date
		filteredForm.endDate = end_date
		filteredForm.value = [start_date, end_date]
	} else {
		clone_forms.startDate = start_date
		clone_forms.endDate = end_date
		clone_forms.value = [start_date, end_date]
	}
	
	return clone_forms
}

const handleSelectEdit = (options: any, value: any) => {
	if (!value.toString()) return
	return _.filter(
		options,
		(result) => result.value.toString() === value.toString()
	)[0]
}

const getOptionsByName = (formList: any, name: string) => {
	return formList.filter((data: any) => data.name === name)[0].options || []
}

const getvalueByName = (formList: any, name: string) => {
	return formList.filter((data: any) => data.name === name)[0].value || ""
}

const assignValueByName = (formList: any, name: string, value: any) => {
	return formList.filter((data: any) => data.name === name)[0].value = value || ''
}

const assignOptionByName = (formList: any, name: string, value: any) => {
	return formList.filter((data: any) => data.name === name)[0].options = value || []
}

const numberSeparator = (number:any) => {
	if(number) {
		number = number.toString()
		var number_string = number.replace(/[^,\d]/g, '').toString(),
			split    = number_string.split(','),
			sisa     = split[0].length % 3,
			rupiah     = split[0].substr(0, sisa),
			ribuan     = split[0].substr(sisa).match(/\d{3}/gi);
			
		if (ribuan) {
			let separator = sisa ? '.' : '';
			rupiah += separator + ribuan.join('.');
		}

		rupiah = split[1] != undefined ? rupiah + ',' + split[1] : rupiah;
		return rupiah
	}
	return ''
};

const validatePhoneNumber = (value:string) => {
	value = value.replace(/\D/g, '')
	if(value.startsWith('0')) {
		value = value.replace('0', '')
	} else if(value.startsWith('62')) {
		value = value.replace('62', '')
	}
	return value
}

const toIdr = (handle: string): string => {
  return 'Rp.' + handle.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1.");
}

export {
	handleChangeField,
	handleChangeSelect,
	handleChangeCheckbox,
	handleChangeFile,
	handleChangeTimeRange,
	handleSelectEdit,
	assignOptionByName,
  assignValueByName,
	getOptionsByName,
	getvalueByName,
	numberSeparator,
	handleChangeMultipleSelect,
	validatePhoneNumber,
	toIdr
}

import NavbarApp from "./navbar";
import Footer from "./footer";
import TitleImageDescSection from "./section/titleImgDesc";
import TematicStudioBox from "./section/temeticStudio";
import TitlePointDescSection from "./section/titlePointDesc";
import TitleWantToBeCreator from "./section/titleWantToBeCreator";
import TitleWantToApply from "./section/titleWantToApply";
import TitleWantToBeInfluencer from "./section/titleWantToBeInfluencer";

export {
  NavbarApp,
  Footer,
  TitleImageDescSection,
  TematicStudioBox,
  TitlePointDescSection,
  TitleWantToBeCreator,
  TitleWantToApply,
  TitleWantToBeInfluencer
};
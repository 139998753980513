// @ts-nocheck
import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import { PiDotsThreeVerticalBold } from "react-icons/pi";
import { useRouter } from 'next/router';
import route_list from '@/constant/routeUrl';
import app_config from '@/constant/config';
import { useTranslation } from 'react-i18next';

export default function MyTransactionDropdown({id, invoice, status}) {
  const router = useRouter()
	const { t } = useTranslation('transactions')

  return (
    <Menu as="div" className="tw-relative tw-inline-block tw-text-left tw-z-[1]">
      <div className=' tw-flex tw-items-center'>
        <Menu.Button className="tw-inline-flex tw-w-full tw-justify-center tw-rounded-md tw-text-lg tw-font-medium tw-text-black tw-hover:bg-opacity-30 tw-focus:outline-none tw-focus-visible:ring-2 tw-focus-visible:ring-white tw-focus-visible:ring-opacity-75">
          <PiDotsThreeVerticalBold size={25}/>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="tw-transition ease-out tw-duration-100"
        enterFrom="tw-transform tw-opacity-0 tw-scale-95"
        enterTo="tw-transform tw-opacity-100 tw-scale-100"
        leave="tw-transition ease-in tw-duration-75"
        leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
        leaveTo="tw-transform tw-opacity-0 tw-scale-95"
      >
        <Menu.Items className="tw-absolute tw-right-0 tw-mt-2 tw-w-56 tw-origin-top-right tw-divide-y tw-divide-gray-100 tw-rounded-md tw-bg-white tw-shadow-lg tw-ring-1 tw-ring-black tw-ring-opacity-5 tw-focus:outline-none">
          <div className="tw-px-1 tw-py-1">
            {status === 1 ? (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? 'tw-bg-gray-100 tw-text-dark-green' : 'tw-text-gray-900'
                    } tw-group tw-flex tw-w-full tw-items-center tw-rounded-md tw-px-2 tw-py-2 tw-text-sm`}
                    onClick={() => router.push(route_list.register)}
                  >
                    {t('notification.campaign.pay')}
                  </button>
                )}
              </Menu.Item>
            ) : (
              <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'tw-bg-gray-100 tw-text-dark-green' : 'tw-text-gray-900'
                  } tw-group tw-flex tw-w-full tw-items-center tw-rounded-md tw-px-2 tw-py-2 tw-text-sm`}
                  onClick={() => router.push(route_list.userTransactionDetail(id))}
                >
                  {t('notification.campaign.view')}
                </button>
              )}
              </Menu.Item>
            )}
           
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? 'tw-bg-gray-100 tw-text-dark-green' : 'tw-text-gray-900'
                  } tw-group tw-flex tw-w-full tw-items-center tw-rounded-md tw-px-2 tw-py-2 tw-text-sm`}
                  onClick={() => window.open(app_config.whatsappTemplateContactSupport(invoice), '_blank')}
                >
                  {t('notification.campaign.contact')}
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

// @ts-nocheck
import { CardTematicStudioBox } from "@/components/atoms"

/* eslint-disable @next/next/no-img-element */
const TematicStudioBox = ({data, className}:any) => {
  return (
    <div className='tw-mb-32'>
      <div className='tw-w-full tw-overflow-hidden tw-grid md:tw-grid-cols-5  md:tw-bg-transparent tw-bg-white tw-rounded-lg tw-mb-10'>
        <div className='md:tw-col-span-2 md:tw-px-0 tw-px-5 md:tw-pt-0 tw-pt-5'>
          <h1 className='md:tw-text-5xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-left tw-w-fit'>{data.title}</h1>
        </div>
        <div className='tw-w-full tw-items-center tw-justify-center tw-z-10 tw-flex tw-flex-col md:tw-col-span-3 md:tw-bg-transparent tw-bg-white tw-p-5 tw-rounded-lg'>
          <p className='tw-max-w-3xl tw-text-left'>
            {data.description}
          </p>
        </div>
      </div>
      <CardTematicStudioBox data={data.list} />
    </div>
  )
}

export default TematicStudioBox
// @ts-nocheck
import Image from 'next/image'
import StyledButton from './index'
import { icon_list } from '@/assets/images'
import { toast } from 'react-toastify'

const ShareArticleButton = () => {
  const handleCopyUrl = () => {
    navigator.clipboard.writeText(window.location.href)
    toast.success('Link copied to clipboard')
  }

  return (
    <StyledButton onClick={handleCopyUrl} className='tw-border-2 tw-border-light-green tw-rounded-md tw-p-1 px-2'>
      <Image src={icon_list.share_icon} alt="" className=' tw-w-3'/>
    </StyledButton>
  )
}

export default ShareArticleButton
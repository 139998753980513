import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
// import enTranslation from './locales/en.json';
// import idTranslation from './locales/id.json';

const resources = {
  en: {
    navbar: require('../public/locales/en/navbar.json'),
    footer: require('../public/locales/en/footer.json'),
    privacy: require('../public/locales/en/privacy.json'),
    terms: require('../public/locales/en/terms.json'),
    product: require('../public/locales/en/product.json'),
    helpcenter: require('../public/locales/en/helpcenter.json'),
    aboutus: require('../public/locales/en/aboutus.json'),
    auth: require('../public/locales/en/auth.json'),
    homepage: require('../public/locales/en/homepage.json'),
    smm: require('../public/locales/en/smm.json'),
    profile: require('../public/locales/en/profile.json'),
    ourteam: require('../public/locales/en/ourteam.json'),
    liveshop: require('../public/locales/en/liveshop.json'),
    blog: require('../public/locales/en/blog.json'),
    press: require('../public/locales/en/press.json'),
    career: require('../public/locales/en/career.json'),
    endorsement: require('../public/locales/en/endorsement.json'),
    creator: require('../public/locales/en/creator.json'),
    livestream: require('../public/locales/en/livestream.json'),
    influencer: require('../public/locales/en/influencer.json'),
    notification: require('../public/locales/en/notification.json'),
    transactions: require('../public/locales/en/transactions.json'),
    agenda: require('../public/locales/en/agenda.json'),
  },
  id: {
    navbar: require('../public/locales/id/navbar.json'),
    footer: require('../public/locales/id/footer.json'),
    privacy: require('../public/locales/id/privacy.json'),
    terms: require('../public/locales/id/terms.json'),
    product: require('../public/locales/id/product.json'),
    helpcenter: require('../public/locales/id/helpcenter.json'),
    aboutus: require('../public/locales/id/aboutus.json'),
    auth: require('../public/locales/id/auth.json'),
    homepage: require('../public/locales/id/homepage.json'),
    smm: require('../public/locales/id/smm.json'),
    profile: require('../public/locales/id/profile.json'),
    ourteam: require('../public/locales/id/ourteam.json'),
    liveshop: require('../public/locales/id/liveshop.json'),
    blog: require('../public/locales/id/blog.json'),
    press: require('../public/locales/id/press.json'),
    career: require('../public/locales/id/career.json'),
    endorsement: require('../public/locales/id/endorsement.json'),
    creator: require('../public/locales/id/creator.json'),
    livestream: require('../public/locales/id/livestream.json'),
    influencer: require('../public/locales/id/influencer.json'),
    notification: require('../public/locales/id/notification.json'),
    transactions: require('../public/locales/id/transactions.json'),
    agenda: require('../public/locales/id/agenda.json'),
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: 'id', // Bahasa awal
  fallbackLng: 'id', // Bahasa fallback jika bahasa yang diminta tidak ditemukan
  interpolation: {
    escapeValue: false // Biarkan escape value agar bisa menggunakan HTML di teks terjemahan
  }
});

export default i18n;

// @ts-nocheck
import { useRef, useEffect } from 'react'
import Image from 'next/image'
import StyledButton from '../button'
import { background_list } from '@/assets/images'
import 'swiper/css';
import 'swiper/css/navigation';


const CardPackage = ({data, cardClass, titleClass, devide}:any) => {

	return (
		<div
			className='tw-max-w-full tw-flex tw-flex-col tw-gap-3' // tw-px-[20px]
		>
      {data.map((item:any, index:number) => {
        const devideByTwo = index % 2 === 0
        const splitedTitle = item.title.split(' ')

        return (
          <div onClick={item.onClick} key={`package-card-${index}`} className={`tw-rounded-[30px] tw-flex tw-flex-col  tw-w-full tw-items-center tw-justify-center tw-relative tw-overflow-hidden ${!devideByTwo && 'tw-border-2 tw-border-dark-green'} ${cardClass}`}>
            <Image src={ devideByTwo ? background_list.card_wave_green_little_m : background_list.card_wave_gray_little_m} alt="arrow rotate" className={`tw-absolute tw-top-0 tw-left-0 tw-w-full tw-scale-[1.1] tw-z-0 tw-h-full ${!devideByTwo && ' tw-grayscale'}`} />
            {devide === false 
            ? (
              <>
                {devideByTwo ? (
                    <h1 className={`md:tw-text-3xl tw-text-center tw-text-2xl tw-font-semibold tw-text-white tw-z-10 tw-mt-5 tw-mb-3 max-w ${titleClass}`}>{item.title}</h1>
                  ) : (
                    <h1 className={`md:tw-text-3xl tw-text-center tw-text-2xl tw-font-semibold tw-z-10 tw-mt-5 tw-mb-3 max-w  tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% ${titleClass}`}>{item.title}</h1>
                  )}</>
            ) : (
              <div className='tw-z-10'>
                {devideByTwo ? (
                  <h1 className={`md:tw-text-3xl tw-text-center tw-text-2xl tw-font-semibold tw-text-white tw-z-10 tw-mt-5 tw-mb-3 max-w ${titleClass}`}>
                    {splitedTitle[0]} <br /> {splitedTitle.map((item:any, index:number) => index !== 0 && `${item} `)} 
                  </h1>
                ) : (
                  <h1 className={`md:tw-text-3xl tw-text-center tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-z-10 tw-mt-5 tw-mb-3 ${titleClass}`}>
                    {splitedTitle[0]} <br /> {splitedTitle.map((item:any, index:number) => index !== 0 && `${item} `)}
                  </h1>
                )}
              </div>
            )}
            
            {item.image && (
              <Image src={item.image} alt='' className='tw-z-10 tw-h-[200px] tw-object-contain' />
            )}
            <StyledButton
              title={item.buttonLabel}
              className={`${devideByTwo ? 'tw-text-white tw-border-white': 'tw-text-dark-green tw-border-dark-green'} tw-mb-10 tw-font-semibold tw-z-10 md:tw-text-xl tw-text-lg tw-items-center tw-border-2 md:tw-px-6 tw-px-3 md:tw-py-2 tw-py-1 tw-rounded-full tw-justify-center md:tw-flex tw-mt-5`}
              onClick={item.onClick}
            />
          </div>
        )
      })}
		</div>
	)
}

export default CardPackage

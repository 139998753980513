// @ts-nocheck

import { Switch } from '@headlessui/react'

type SwitchLanguageProps = {
  handleOnChange: (e:any) => void
  isEnable: boolean
}

const SwitchLanguage = ({handleOnChange, isEnable}:SwitchLanguageProps) => {
  return (
    <Switch
      checked={isEnable}
      onChange={handleOnChange}
      className={`${
        isEnable ? 'tw-bg-gray-200' : 'tw-bg-gray-200'
      } tw-inline-flex tw-h-6 tw-w-14 tw-items-center tw-rounded-full tw-relative`}
    >
      <div
        className={`${
          isEnable ? 'tw-translate-x-7' : 'tw-translate-x-1'
        } btn-dark-to-light-green-gradient tw-inline-block tw-h-4 tw-transform tw-rounded-full tw-transition tw-text-white tw-w-6`}
      />
      <div className={`${!isEnable ? 'tw-text-white' : 'tw-text-gray-600'} tw-absolute tw-left-[10px] tw-text-[12px]`}>ID</div>
      <div className={`${isEnable ? 'tw-text-white' : 'tw-text-gray-600'} tw-absolute tw-right-[7px] tw-text-[12px]`}>EN</div>
    </Switch>
  )
}

export default SwitchLanguage
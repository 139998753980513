/* eslint-disable @next/next/no-img-element */
// @ts-nocheck
import CenteredRadiantGradient from "../gradient/centered-radiant-gradient"

const CardPointToPoint = ({data, className}:any) => {

  return (
    <div className={`tw-grid md:tw-grid-cols-2 tw-gap-10 tw-gap-y-[20px] tw-relative ${className}`}>
      <CenteredRadiantGradient />
      {data.map((item:any, index:number) => {
        return (
          <div className="tw-w-full md:tw-mb-0 tw-rounded-xl tw-flex tw-flex-col tw-z-[10]" key={`cpt-${index}`}>
            <h2 className="md:tw-text-4xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-mb-3 tw-w-fit">
              {item.title}
            </h2>
            <div className=' tw-bg-white tw-px-6 tw-py-4 tw-rounded-xl tw-h-fit h-fill-avail'>
              <div className="tw-text-base tw-leading-7 tw-black tw-text-left" dangerouslySetInnerHTML={{__html: item.description}}></div>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default CardPointToPoint
/* eslint-disable @next/next/no-img-element */
// @ts-nocheck
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRef, useEffect } from 'react'
import { Navigation } from 'swiper/modules'
import Image from 'next/image'
import StyledButton from '../button'
import { background_list, icon_list } from '@/assets/images'
import 'swiper/css';
import 'swiper/css/navigation';
import dayjs from 'dayjs'
import route_list from '@/constant/routeUrl'
import { useRouter } from 'next/router'


const PackageSwiper = ({data, cardClass, titleClass}:any) => {
	const swiperElRef = useRef<any>(null)
	const router = useRouter()

	return (
		<div className="tw-gap-5 tw-relative md:tw-px-6">
      <Swiper
        className='md:tw-max-w-full tw-max-w-sm'
        ref={swiperElRef}
        spaceBetween={10}
        // slidesPerView={3}
        autoplay
        breakpoints={{
          500: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          1024: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
      >
        {data.map((item, index) => {
          return (
            <SwiperSlide key={`lt-${index}`}>
              <button className="tw-mb-6 tw-flex md:tw-flex-col tw-flex-col-reverse tw-bg-white tw-rounded-xl tw-w-full" onClick={() => router.push(route_list.agendaDetail(item.id))}>
                <div className="tw-mb-0 tw-ml-auto tw-w-full tw-shrink-0 tw-grow-0 tw-basis-auto">
                  <div className="tw-relative tw-overflow-hidden md:tw-rounded-t-lg md:tw-rounded-b-none tw-rounded-b-lg tw-bg-cover tw-bg-no-repeat tw-h-[200px]" data-te-ripple-init data-te-ripple-color="light">
                    <img src={item.image} className="tw-w-full tw-object-cover" alt={item.title} />
                  </div>
                </div>

                <div className="md:tw-mb-6 tw-mb-2 tw-mr-auto tw-w-full tw-shrink-0 tw-grow-0 tw-basis-auto tw-p-4">
                  <div className='tw-flex tw-flex-row tw-gap-4'>
                    <div className="tw-flex tw-flex-col tw-text-neutral-500">
                      <small>{dayjs(item.date).format("MMM")}</small>
                      <span className='tw-font-bold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-30% tw-to-light-green tw-to-70% tw-text-2xl'>{dayjs(item.date).format("DD")}</span>
                    </div>
                    <div className='tw-flex tw-flex-col'>
                      <h5 className="tw-mb-3 tw-text-md tw-font-bold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-30% tw-to-light-green tw-to-70% tw-text-left">{item.title}</h5>
                      <p className="tw-text-neutral-500 tw-text-sm tw-text-left">
                        {item.description}
                      </p>
                    </div>
                  </div>
                </div>
              </button>
            </SwiperSlide>
          )
        })}
      </Swiper>
      <div className='tw-w-[105%] tw-h-full tw-absolute md:tw-flex tw-top-0 tw-left-[-28px] tw-hidden tw-flex-row tw-justify-between'>
        <div className='tw-flex tw-items-center'>
          <button onClick={() => swiperElRef.current.swiper.slidePrev()}>
              <Image
                alt=''
                src={icon_list.prev_gradient_green}
                className='tw-cursor-pointer tw-h-12 '
              />
            </button>
        </div>
        <div className='tw-flex tw-items-center'>
          <button onClick={() => {
            swiperElRef.current.swiper.slideNext()
            console.log(swiperElRef.current.swiper)
          }}>
            <Image
              alt=''
              src={icon_list.next_gradient_green}
              className='tw-cursor-pointer tw-h-12 '
            />
          </button>
        </div>
      </div>
 
    </div>
	)
}

export default PackageSwiper

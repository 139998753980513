// @ts-nocheck

import Head from "next/head"

const MetaTag = (props:any) => {
  const {title, description, keywords, ogTitle, ogDescription, ogType, ogUrl, ogImage} = props

  return (
    <Head>
      <title>{title || "Marketplace Influencer untuk UMKM | socialbread.id"}</title>
      <meta charSet="utf-8"/>
      <meta httpEquiv="X-UA-Compatible" content="IE=edge"/>
      <meta name="viewport" content="width=device-width, initial-scale=1.0, minimum-scale=1.0, shrink-to-fit=no"/>
      <meta name="apple-mobile-web-app-capable" content="yes"/>
      <meta name="apple-mobile-web-app-title" content="socialbread.id"/>
      <meta name="apple-mobile-web-app-status-bar-style" content="black"/>
      <meta name="title" content={title || "Marketplace Influencer untuk UMKM | socialbread.id"}/>
      <meta name="description" content={description || "Marketplace Influencer untuk UMKM Indonesia"}/>
      <meta name="keywords" content={keywords || "Marketplace Influencer untuk UMKM | socialbread.id"}/>
      <meta name="theme-color" content="#F82D2C"/>
      <meta name="twitter:card" content="summary"/>
      <meta name="twitter:title" content={title || "Marketplace Influencer untuk UMKM | socialbread.id"}/>
      <meta property="title"/>
      <meta property="og:title" content={ogTitle || "Marketplace Influencer untuk UMKM Indonesia"} key="ogtitle"/>
      <meta property="og:description" content={ogDescription || "Marketplace Influencer untuk UMKM Indonesia"} key="ogdescription"/>
      <meta property="og:type" content={ogType || "website"} key="ogtype"/>
      <meta property="og:url" content={ogUrl || "https://socialbread.id/"} key="ogurl"/>
      <meta property="og:image" content={ogImage || "https://assets.adira.one/sp/icons/logo3.svg"} key="ogimage"/>
      <meta property="twitter:card" content="summary_large_image"/>
      <meta property="twitter:title" content={ogTitle || "Marketplace Influencer untuk UMKM Indonesia"} key="ogtitle"/>
			<meta property="twitter:description" content={ogDescription || "Marketplace Influencer untuk UMKM Indonesia"} />
      <meta property="twitter:image" content={ogImage || "https://assets.adira.one/sp/icons/logo3.svg"} />
      <meta property="twitter:url" content={ogUrl || "https://socialbread.id/"} key="ogurl"/>
      <link rel="canonical" href={ogUrl || "https://socialbread.id/"} key="ogurl"/>

         
    </Head>
  )
}

export default MetaTag
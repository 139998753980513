/* eslint-disable @next/next/no-img-element */
// @ts-nocheck
import { Swiper, SwiperSlide } from 'swiper/react'
import {MobileView, BrowserView} from 'react-device-detect';
import Image from "next/image"
import 'swiper/css';

const CardTematicStudioBox = ({data, className}:any) => {
  // console.log(data)
  return (
    <div>
      <BrowserView className='tw-flex tw-flex-col tw-gap-10 tw-font-poppins tw-rounded-lg'>
        {data.map((item:any, index:number) => {
          return (
            <div className="tw-justify-center tw-flex-1 tw-max-w-6xl" key={`tmetic-${index}`}>
              <h2 className='md:tw-text-2xl tw-text-xl tw-font-semibold tw-mb-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-center'>{item.title}</h2>
              <div className="tw-relative">
                <div className={`tw-grid ${index === 0 ? 'md:tw-grid-cols-2' : 'md:tw-grid-cols-3'} tw-gap-5 md:tw-gap-y-[70px] tw-gap-y-[50px] ${className}`}>
                  {item.images.map((image:any, imgindex:number) => {
                    return (
                      <div key={`imgst-${imgindex}`}>
                        <Image src={image} alt="" />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
          )
        })}
      </BrowserView>
      <MobileView className='tw-flex tw-flex-col tw-gap-10 tw-font-poppins tw-rounded-lg'>
        {data.map((item:any, index:number) => {
          return (
            <div className="tw-justify-center tw-flex-1 tw-max-w-6xl" key={`tmetic-${index}`}>
              <h2 className='md:tw-text-2xl tw-text-xl tw-font-semibold tw-mb-10 tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-center'>{item.title}</h2>
              <Swiper
                spaceBetween={50}
                slidesPerView={'auto'}
                centeredSlides={true}
              >
                {item.images.map((item:any, index:number) => {
                  return (
                    <SwiperSlide key={`tcard-${index}`}>
                      <img
                        alt=''
                        src={item}
                        className='tw-cursor-pointer tw-h-full tw-w-full'
                      />
                    </SwiperSlide>
                  )
                })}
              </Swiper>
            </div>
          )
        })}
      </MobileView>
      
      
    </div>
  )
}

export default CardTematicStudioBox
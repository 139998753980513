import _ from 'lodash'

type sidebarListType = {
  id: number;
  title: string;
  route: string;
  isActive: boolean;
}

type State = {
  sidebar_items: sidebarListType[];
  is_open_on_mobile: boolean;
  language: string;
  role: string;
  token: string;
  dataUser: any;
  rememberMe: string;
};

type Action =
| { type: "SetActiveSidebar"; payload?: sidebarListType[]; selectedItem?: sidebarListType }
| { type: "SetOpenOnMobile"; isOpen: boolean }
| { type: "SetLanguage"; language: string }
| { type: "SetUserDataRawLS"; data: any }
| { type: "SetDetailData"; dataUser: any }
| { type: "SetRememberMe"; rememberMe: string }


function dataHandlerReducer(state: State, action: Action): State {
  switch (action.type) {
    case "SetActiveSidebar":
      const clone_sidebar_items = _.cloneDeep(state.sidebar_items)
      _.filter(clone_sidebar_items, (item) => {
        if (item.id === action.selectedItem?.id) {
          item.isActive = true
        } else {
          item.isActive = false
        }
      })
      
      return {
        ...state,
        sidebar_items: clone_sidebar_items,
      };
    case "SetOpenOnMobile":
      return {
        ...state,
        is_open_on_mobile: action.isOpen,
      };
    case "SetLanguage":
      return {
        ...state,
        language: action.language,
      };
    case "SetUserDataRawLS":
      return {
        ...state,
        token: action.data.token,
        role: action.data.role,
      };
    case "SetDetailData":
      return {
        ...state,
        dataUser: action.dataUser
      };
      case "SetRememberMe":
      return {
        ...state,
        rememberMe: action.rememberMe
      };
    default:
      return state;
  }
}

export default dataHandlerReducer
const app_config = { 
  env: process.env.NEXT_PUBLIC_VARIABLE_DEV_MODE,
  api_url: process.env.NEXT_PUBLIC_API_URL, 
  api_static: process.env.NEXT_PUBLIC_API_STATIC_BE, 
  api_storage: process.env.NEXT_PUBLIC_API_STORAGE,
  asset_url: process.env.NEXT_PUBLIC_ASSET_URL, 
  secret_key: process.env.NEXT_PUBLIC_CRYPTO_SECRET_KEY, 
  api_sample: process.env.NEXT_PUBLIC_API_SAMPLE, 
  pixel_key: process.env.NEXT_PUBLIC_PIXEL_ID, 
  gtm_key: process.env.NEXT_PUBLIC_GTM_CODE,
  whatsappTemplate: 'https://wa.me/+6281287672836?text=Halo Tim Social Bread, Saya ingin tahu lebih banyak tentang solusi yang ditawarkan oleh kalian. Apa bisa di bantu jelaskan?',
  whatsappTemplateContactSupport: (invoice:any) => `https://wa.me/+6281287672836?text=Halo Tim Social Bread, Saya ingin menanyakan transaksi dengan invoice ${invoice}. Apa bisa di bantu?`,
  AndroidClientAppUrl: "https://play.google.com/store/apps/details?id=id.socialbread.client",
  AndroidPartnerAppUrl: "https://play.google.com/store/apps/details?id=id.socialbread.creator",
  AppstoreClientAppUrl: "https://apps.apple.com/id/app/social-bread/id6464466691",
  AppstorePartnerAppUrl: "https://apps.apple.com/us/app/sb-partners/id6449588712",
  instagramUrl: "https://www.instagram.com/social.bread/?hl=en",
  tiktokUrl: "https://www.tiktok.com/@social.bread?lang=en",
  linkedInUrl: "https://id.linkedin.com/company/social-bread-indonesia",
  enterpriseUrl: "https://sbe.socialbread.id/",
} 

export default app_config
/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
// @ts-nocheck
import { CardColList } from "@/components/atoms"
import Image from "next/image"

const TitleImageDescSection = ({data, isReverse}:any) => {
  return (
    // md:tw-bg-transparent tw-bg-white 
    <div className='tw-m-auto tw-mb-20'>
      <div className='tw-w-full tw-overflow-hidden tw-grid md:tw-grid-cols-5  tw-rounded-lg tw-mb-10'>
        <div className='md:tw-col-span-2 md:tw-px-0 tw-px-5 md:tw-pt-0 tw-pt-5 tw-flex tw-items-center'>
          <h1 className='md:tw-text-6xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-text-left tw-w-fit'>{data.title}</h1>
        </div>
        <div className='tw-w-full tw-items-center tw-justify-center tw-z-10 tw-flex tw-flex-col tw-p-5 md:tw-col-span-3 tw-rounded-lg'>
          <p className='tw-max-w-3xl tw-text-left' dangerouslySetInnerHTML={{__html: data.description}}></p>
        </div>
      </div>
      {isReverse ? (
        <div className='tw-w-full tw-overflow-hidden tw-grid md:tw-grid-cols-2 tw-rounded-lg tw-gap-5'>
          <CardColList data={data.points} />
          <div className=''>
            <Image src={data.image} alt="" className="tw-relative tw-z-40 tw-object-cover tw-w-full tw-h-full tw-rounded-xl" />
          </div>
        </div>
      ) : (
        <div className='tw-w-full tw-overflow-hidden tw-grid md:tw-grid-cols-2 tw-rounded-lg tw-gap-5'>
          <div className=''>
            <Image src={data.image} alt="" className="tw-relative tw-z-40 tw-object-cover tw-w-full tw-h-full tw-rounded-xl" />
          </div>
          <CardColList data={data.points} />
        </div>
      )}
    </div>
  )
}

export default TitleImageDescSection
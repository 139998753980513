/* eslint-disable @next/next/no-img-element */
/* eslint-disable react/no-unescaped-entities */
// @ts-nocheck
import { CardColList, CenteredRadiantGradient } from "@/components/atoms"

const TitlePointDescSection = ({data}:any) => {
  return (
    <div className='tw-m-auto tw-mb-20'>
      <div className='tw-w-full tw-overflow-hidden tw-grid md:tw-grid-cols-5  md:tw-bg-transparent tw-bg-white tw-rounded-lg tw-mb-10'>
        <div className='md:tw-col-span-2 md:tw-px-0 tw-px-5 md:tw-pt-0 tw-pt-5'>
          <h1 className='md:tw-text-5xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-90% tw-text-left tw-w-fit'>{data.title}</h1>
        </div>
        <div className='tw-w-full tw-items-center tw-justify-center tw-z-10 tw-flex tw-flex-col md:tw-col-span-3  tw-p-5 tw-rounded-lg'>
          <p className='tw-max-w-3xl tw-text-left'>
            {data.description}
          </p>
        </div>
      </div>
      <div className='tw-w-full tw-grid md:tw-grid-cols-2 tw-rounded-lg tw-gap-5 tw-relative'>
        <CenteredRadiantGradient />
        {data.points.map((item:any, index:number) => {
          return (
            <div className='tw-bg-white tw-p-5 tw-rounded-lg tw-z-[1]' key={`ccl-${index}`}>
              <h5 className="md:tw-text-2xl tw-text-lg tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-mb-3 tw-w-fit">
                {item.title}
              </h5>
              <p className='tw-max-w-3xl tw-text-left'>
                {item.description}
              </p>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default TitlePointDescSection
// @ts-nocheck
import Image from "next/image"

/* eslint-disable @next/next/no-img-element */
const CardWithDescription = ({data}:any) => {
  return (
    <div>
      {data.map((item:any, index:number) => {
        const isZero = (index+1) % 2 === 0
        return (
          <div key={`smm-top-section-${index}`} className="tw-flex tw-items-center md:tw-bg-transparent tw-bg-white tw-font-poppins tw-rounded-lg tw-mb-12">
            <div className="tw-justify-center tw-flex-1 tw-max-w-6xl md:tw-pt-4 tw-pt-0 tw-mx-auto md:tw-px-0">
              <div className={`tw-flex ${isZero ? 'md:tw-flex-row' : 'md:tw-flex-row-reverse'} tw-flex-col-reverse md:tw-gap-16`}>
                  <div className="tw-w-full tw-mb-0 md:tw-w-1/2 md:tw-p-0 tw-p-5 md:tw-h-full ">
                      <Image src={item.image} alt="" className="tw-relative tw-z-40 tw-object-cover tw-w-full md:tw-h-96 tw-rounded-xl" />
                  </div>
                  <div className="tw-w-full md:tw-px-10 tw-px-6 md:tw-py-7 tw-py-4 tw-mb-0 md:tw-w-1/2 md:tw-bg-white tw-bg-transparent tw-rounded-xl">
                      <h2 className="tw-mb-4 md:tw-text-4xl tw-text-2xl tw-font-semibold tw-text-transparent tw-text-left tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green">
                        {item.title}
                      </h2>
                      <p className="tw-mb-10 tw-text-base tw-leading-7 tw-black">
                        {item.description1}
                      </p>
                      <p className="md:tw-mb-0 tw-mb-0 tw-text-base tw-leading-7 tw-black">
                        {item.description2}
                      </p>
                  </div>
              </div>
            </div>
          </div>
        )
        })}
    </div>
  )
}

export default CardWithDescription
// @ts-nocheck

import Spinner from "./spinner"
import { useContext } from "react"
import DataHandlerContext from "@/utility/context/dataHandler"

const PageLoading = () => {
  const {language}:any = useContext(DataHandlerContext)

  return (
    <div className='tw-h-screen w-full tw-flex tw-items-center tw-justify-center tw-flex-col'>
      <Spinner />
      <span className="tw-mt-3">{language === "en" ? "Loading data..." : "Memuat data..." }</span>
    </div>
  )
}

export default PageLoading
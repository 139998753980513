/* eslint-disable react/no-unescaped-entities */
/* eslint-disable @next/next/no-img-element */
// @ts-nocheck
import { background_list } from "@/assets/images"
import Image from "next/image"

const CardCreator = ({data, images}:any) => {
  return (
    <div className="tw-grid tw-gap-5 tw-place-items-center">
      {data.map((item:any, index:number) => {
        const devide = index % 2
        
        return (
          <div className="tw-flex tw-flex-row tw-items-center tw-w-full" key={`creat-${index}`}>
            <div className='tw-flex tw-items-center tw-justify-center tw-relative tw-w-full md:tw-h-[300px] tw-rounded-xl tw-overflow-hidden'>
              <div className='tw-h-full tw-w-full md:tw-pt-10 tw-absolute'>
                <div className='tw-h-full tw-w-full tw-rounded-xl tw-left-0 tw-bottom-0'>
                  <Image src={devide === 0 ? images[0] : images[1]} alt="" className='tw-w-full tw-h-full tw-object-fill' />
                </div>
              </div>
              <div className='md:tw-grid tw-flex tw-flex-col tw-items-center md:tw-grid-cols-12 tw-z-[1] tw-px-10 tw-py-3'>
                <div className='md:tw-w-[280px] md:tw-h-[300px] md:tw-border-0 md:tw-rounded-none tw-w-[80px] tw-h-[80px] md:tw-col-span-3 md:tw-mb-0 tw-mb-3 tw-rounded-full tw-border-2 tw-border-[#FFCEA1] tw-overflow-hidden'>
                  <Image src={item.image} alt="" className=' tw-w-full tw-h-full tw-object-contain' />
                </div>
                <div className='tw-flex tw-flex-col md:tw-col-span-9 tw-justify-center md:tw-pl-10'>
                  <h1 className='tw-text-white md:tw-text-left tw-text-center md:tw-text-xl tw-text-sm'>"{item.description}"</h1>
                  <h2 className='md:tw-text-xl md:tw-text-left tw-text-center tw-text-sm tw-font-semibold tw-text-[#FFCEA1] tw-mt-2'>{item.name}</h2>
                </div>
              </div>
            </div>
          </div>
        )
      })}
      
      
    </div>
  )
}

export default CardCreator
/* eslint-disable @next/next/no-img-element */
const CardColList = ({data, className}:any) => {
  return (
    <div className='tw-w-full tw-items-center tw-justify-center tw-z-10 tw-flex tw-flex-col tw-rounded-lg tw-gap-5'>
      {data.map((item:any, index:number) => {
        return (
          <div className='tw-bg-white tw-p-5 tw-rounded-lg' key={`ccl-${index}`}>
            <div className="tw-flex tw-flex-row md:tw-text-2xl tw-text-lg tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-mb-3 tw-w-fit tw-text-left">
              <span className="tw-mr-1">
                {index+1}.
              </span>
              <span>
                {item.title}
              </span>
            </div>
            <p className='tw-max-w-3xl tw-text-left md:tw-pl-6 tw-pl-4'>
              {item.description}
            </p>
          </div>
        )
      })}
    </div>
  )
}

export default CardColList
// @ts-nocheck
import Image from "next/image"

/* eslint-disable @next/next/no-img-element */
const ListTeam = ({isCentered,data}:any) => {
  

  return (
    <div className="tw-grid md:tw-grid-cols-6 tw-grid-cols-2 tw-gap-5 tw-place-items-center">
      {data.map((item:any, index:any) => {
        return (
          <div className={`tw-flex tw-flex-col tw-items-center md:tw-col-span-2 ${isCentered && index === 3 && `md:tw-col-start-2`}`} key={`lt-${index}`}>
            <div className='tw-flex tw-items-center tw-justify-center tw-relative tw-mb-3'>
              <div className='tw-h-full tw-w-full tw-pt-10 tw-absolute'>
                <div className='bg-yellow-green-tb tw-h-full tw-w-full tw-rounded-xl tw-left-0 tw-bottom-0'></div>
              </div>
              <Image src={item.image} alt="" className=' tw-z-[1] tw-w-full tw-h-full tw-object-contain' />
            </div>
            <h1 className='tw-text-dark-green tw-font-semibold md:tw-text-xl tw-text-sm tw-text-center'>{item.name}</h1>
            <span className='md:tw-text-xl tw-text-sm tw-text-center'>{item.position}</span>
          </div>
        )
      })}
    </div>
  )
}

export default ListTeam
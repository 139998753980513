/* eslint-disable react-hooks/exhaustive-deps */
// @ts-nocheck
import type { AppProps } from 'next/app'
import { Provider } from 'react-redux';
import store from '../integration/store'
import { QueryClient, QueryClientProvider } from "react-query";
import { appWithTranslation } from 'next-i18next'
import { Footer, NavbarApp } from '@/components/molecules';
import i18n from "../i18n"
import { I18nextProvider } from 'react-i18next';
import { useEffect, useReducer, useState } from 'react';
import { Spinner } from '@/components/atoms';
import { Montserrat } from 'next/font/google'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '@/assets/styles/globals.scss'
import '@/assets/styles/overwrite.scss'
import { getCookie, setCookie } from '@/utility/helper/cookies';
import Script from "next/script";
import { useRouter } from 'next/router';
import route_list from '@/constant/routeUrl';
import { includes } from 'lodash';
import app_config from '@/constant/config';
import { pixleBlacklistRoute } from '@/constant/variables';
import DataHandlerContext from '@/utility/context/dataHandler';
import dataHandlerReducer from '@/utility/context/dataHandler/reducer';
import { getUserDataRawLS, getUserDetailData } from '@/utility/helper/generalHandler';
import Router from 'next/router';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

const montserrat = Montserrat({
  subsets: ['latin'],
})

const clairtyCode = `
(function (c,l,a,r,i,t,y){
    c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
    t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
    y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
})(window, document, "clarity", "script", "knuo67sgnv")`;

const pixelCode = `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '${app_config.pixel_key}');
fbq('track', 'PageView');`

const hotjrCode = `(function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:3840126,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`


const App = ({ Component, pageProps }: AppProps) => {
  const [isLoading, setLoading] = useState(true)
  const [isWindow, setIsWindow] = useState(false)
  const [whatLanguage, setWhatLanguage] = useState('')
  const router = useRouter()

  const [{
    sidebar_items,
		is_open_on_mobile,
    language,
    token,
    role,
    dataUser,
    rememberMe
  }, dispatch] = useReducer(dataHandlerReducer, {
    sidebar_items: [],
		is_open_on_mobile: false,
    language: '',
    token: '',
    role: '',
    dataUser: {},
    rememberMe: ''
  })

  // console.log(pageProps)
  // useEffect(() => {
  //   import('react-facebook-pixel')
  //     .then((x) => x.default)
  //     .then((ReactPixel) => {
  //       ReactPixel.init('1211768843052759') // facebookPixelId
  //       ReactPixel.pageView()
  //       console.log("I got here")
  //       router.events.on('routeChangeComplete', () => {
  //         ReactPixel.pageView()
  //       })
  //     })
  // }, [router.events])
  useEffect(() => {
    if(window !== undefined) {
      if(window.fbq && isWindow === false) {
        setIsWindow(true)
      }
    }
  })

  useEffect(() => {
    if(isWindow) {
      if(!pixleBlacklistRoute.includes(router.route)) {
        window.fbq('track', 'Page Viewed', {content_name: router.route})
        console.log("I got here", router)
      }
    }
  }, [router, isWindow])

  useEffect(() => {
    const savedLanguage = getCookie('language');

    dispatch({type: "SetLanguage", language: getCookie('language')})
    dispatch({type: "SetUserDataRawLS", data: getUserDataRawLS() || {token: '', role: 'user'}})
    dispatch({type: "SetDetailData", dataUser: getUserDetailData()})
    dispatch({type: "SetRememberMe", rememberMe: getCookie('remember_me')})

    if (savedLanguage) {
      i18n.changeLanguage(savedLanguage);
    } else {
  		setCookie("language", 'id', 3)
    }
    setTimeout(() => {
      setLoading(false)
    }, 500);
  }, []);


  Router.events.on('routeChangeStart', () => setLoading(true));
  Router.events.on('routeChangeComplete', () => setLoading(false));

  return (
    <DataHandlerContext.Provider value={{sidebar_items, rememberMe, is_open_on_mobile, language, token, role, dataUser, dispatch}}>
      {isLoading && <div className='tw-w-full tw-flex tw-h-full tw-items-center tw-justify-center tw-fixed tw-z-[999] tw-bg-white'><Spinner /></div>}
      <main className={montserrat.className}>
        <Provider store={store}>
          <ToastContainer />
          <I18nextProvider i18n={i18n}>
          <QueryClientProvider client={queryClient}>
            <NavbarApp />
            <Script id="pixle-code">
              {pixelCode}
            </Script>
            <Script id="hot-jar">
              {hotjrCode}
            </Script>
            <Script id="ms-clarity" strategy="afterInteractive">
              {clairtyCode}
            </Script>
            <Script defer async src={`https://www.googletagmanager.com/gtag/js?id=${app_config.gtm_key}`}/>
            <script
              dangerouslySetInnerHTML={{
                __html: `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${app_config.gtm_key}', {
                page_path: window.location.pathname,
              });
            `,
              }}
            />
            <noscript>
              <iframe src={`https://www.googletagmanager.com/ns.html?id=${app_config.gtm_key}`} height="0" width="0" style={{display: 'none', visibility: 'hidden'}}></iframe>
            </noscript>
              <Component {...pageProps} />
            <Footer />
          </QueryClientProvider>
          </I18nextProvider>
        </Provider>
      </main>
    </DataHandlerContext.Provider>
  )
}
export default appWithTranslation(App)

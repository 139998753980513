const route_list = {
  home: '/',
  example: '/example',
  blog: '/blog',
  blogDetail: (detail:string) =>  `/blog/${detail}`,
  sample_redux: '/example/sample-redux',
  sample_react_query: '/example/sample-react-query',
  sample_detail_page: (detail:string) => `/example/${detail}`,
  social_media_management: '/social-media-management',
  provacy_policy: '/privacy-policy',
  terms_of_service: '/terms-of-use',
  press: '/press',
  pressDetail: (detail:string) =>  `/press/${detail}`,
  login: '/login',
  register: '/register',
  otp: '/login/otp',
  about_us: '/about-us',
  help_center: '/help-center',
  help_center_detail: (detail:string) => `/help-center/${detail}`,
  live_shopping: '/live-shopping-experience',
  our_team: '/our-team',
  career: '/career',
  endorsement: '/endorsement',
  content_creator: '/content-creator',
  live_streamer: '/live-streamer',
  influencer: '/influencer',
  agenda: '/events',
  agendaDetail: (detail:string) =>  `/events/${detail}`,
  userProfile: '/user/profile',
  userTransaction: '/user/my-transaction',
  userTransactionDetail: (detail:string) =>  `/user/my-transaction/${detail}`,
  userNotification: '/user/notification',
  devTest: '/dev-test',
  order_marketing: '/order/marketing',
  order_payment: '/order/payment',
  order_payment_detail: '/order/payment-detail',
};

export default route_list;
// @ts-nocheck
const CardRecognitions = ({data}:any) => {
  return (
    <div className="tw-justify-center tw-flex-1 tw-max-w-6xl tw-pt-4 tw-mx-auto md:tw-px-6">
      <h2 className="tw-mb-10 md:tw-text-3xl tw-text-xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-70% tw-text-center">RECOGNITIONS</h2>
      <div className="tw-flex md:tw-flex-row tw-flex-col tw-gap-5">
        {data.map((item:any, index:number) => {
          return (
            <div className="tw-w-full md:tw-w-1/3 md:tw-mb-0 tw-rounded-xl" key={`cr-${index}`}>
              <div className='tw-flex tw-flex-col tw-p-4 tw-py-3 tw-gap-7'>
                <span className='tw-text-dark-green'>{item.year}</span> 
                <span className='tw-text-xl tw-font-bold'>{item.title}</span>
                <span className='tw-text-dark-green tw-font-semibold'>{item.name}</span>
              </div>
          </div>
          )
        })}
      </div>
      <div className="tw-inline-flex tw-items-center tw-justify-center tw-w-full">
        <hr className="tw-w-[300px] tw-h-[1px] tw-my-8 tw-bg-dark-green tw-border-0 tw-rounded" />
        <div className="tw-absolute tw-px-4 -tw-translate-x-1/2 tw-bg-[#F1F1F1] tw-left-1/2">
          <h2 className="md:tw-text-xl tw-text-sm tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-text-center">And many more</h2>
        </div>
      </div>
    </div>
  )
}

export default CardRecognitions
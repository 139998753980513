// @ts-nocheck
import { background_list, icon_list } from '@/assets/images'
import { StyledButton } from '@/components/atoms'
import Image from 'next/image'
import React from 'react'
import { AiOutlineInstagram, AiOutlineMail, AiOutlineWhatsApp, AiFillLinkedin, AiOutlineRight } from 'react-icons/ai'
import { FaTiktok } from "react-icons/fa6";
import {
	Accordion,
	AccordionItem,
	AccordionItemHeading,
	AccordionItemButton,
	AccordionItemPanel,
  AccordionItemState
} from 'react-accessible-accordion'
import { useTranslation } from 'next-i18next'
import { useRouter } from 'next/router'
import route_list from '@/constant/routeUrl'
import app_config from '@/constant/config'
import { pixleBlacklistRoute } from '@/constant/variables'

const Footer = () => {
	const router = useRouter()
	const locale_type = router.locale === 'en'
	const { t } = useTranslation('footer')

	const menu_list = [
		{
			name: t('footer.menu.services.mainmenu'),
			child: [
				{
					label: t('footer.menu.services.submenu1'),
					href: route_list.social_media_management,
				},
				{
					label: t('footer.menu.services.submenu2'),
					href: route_list.live_shopping,
				},
				{
					label: t('footer.menu.services.submenu3'),
					href: route_list.endorsement,
				},
				{
					label: t('footer.menu.services.submenu4'),
					href: app_config.enterpriseUrl,
				},
			],
			current: true,
		},
		{
			name: t('footer.menu.partners.mainmenu'),
			child: [
				{
					label: t('footer.menu.partners.submenu1'),
					href: route_list.content_creator,
				},
				{
					label: t('footer.menu.partners.submenu2'),
					href: route_list.live_streamer,
				},
				{
					label: t('footer.menu.partners.submenu3'),
					href: route_list.influencer,
				},
			],
			current: false,
		},
		{
			name:  t('footer.menu.learn.mainmenu'),
			child: [
				{
					label: t('footer.menu.learn.submenu1'),
					href: route_list.about_us,
				},
				{
					label: t('footer.menu.learn.submenu2'),
					href: route_list.agenda,
				},
				{
					label: t('footer.menu.learn.submenu3'),
					href: route_list.press,
				},
				{
					label: t('footer.menu.learn.submenu4'),
					href: route_list.our_team,
				},
				{
					label: t('footer.menu.learn.submenu5'),
					href: route_list.career,
				},
				{
					label: t('footer.menu.learn.submenu6'),
					href: route_list.help_center,
				},
				{
					label: t('footer.menu.learn.submenu9'),
					href: route_list.blog,
				},
				{
					label: t('footer.menu.learn.submenu7'),
					href: route_list.provacy_policy,
				},
				{
					label: t('footer.menu.learn.submenu8'),
					href: route_list.terms_of_service,
				},
			],
			current: false,
		},
	]

	return (
		<footer className='tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-text-center tw-text-white lg:tw-text-left'>
			<div className='tw-mx-auto tw-max-w-7xl tw-px-4'>
				<div className='tw-flex tw-items-center tw-justify-center tw-border-b-2 tw-border-neutral-200 md:tw-py-20 tw-py-6 lg:tw-justify-between'>
					<div className='tw-mr-12 tw-block'>
						<h1 className='md:tw-text-7xl tw-text-xl tw-text-left tw-font-semibold'>
							{t('footer.question')}
						</h1>
					</div>
					<div className='md:tw-w-[400px] tw-flex tw-justify-end'>
						<StyledButton
							title={t('footer.lets_talk')}
							className='tw-text-white tw-w-max tw-py-2 tw-font-semibold md:tw-text-xl tw-text-xs tw-items-center tw-border-2 tw-border-white md:tw-px-6 tw-px-3 md:tw-py-2 tw-rounded-full tw-justify-center md:tw-flex'
							onClick={() => {
								if(!pixleBlacklistRoute.includes(router.route)) {
									window.fbq('track', 'Contact us', {content_name: router.route})
								}
								window.open(app_config.whatsappTemplate)
							}}
						/>
					</div>
				</div>

				<div className='tw-py-10 tw-text-center md:tw-text-left'>
					<div className='tw-grid-1 tw-grid tw-gap-8 md:tw-grid-cols-3 lg:tw-grid-cols-5'>
						<div className='tw-pt-3'>
							<Image
								alt=''
								src={background_list.sosbread_white_logo}
								className='tw-cursor-pointer tw-h-12 tw-w-auto'
							/>
							<p className='tw-mt-2 tw-text-left'>
								The Breeze BSD Unit L78, Kabupaten Tangerang, Banten 15345
							</p>
						</div>
						{menu_list.map((item, index) => (
							<div key={`footer-menu-${index}`} className='md:tw-block tw-hidden'>
								<span className='tw-mb-5 tw-flex tw-justify-center tw-font-semibold md:tw-justify-start tw-text-left'>
									{item.name}
								</span>
								{item.child.map((child, index) => (
									<p key={`footer-menu-child-${index}`} className='tw-mb-3'>
										<button onClick={() => router.push(child.href)} className='tw-text-white hover:tw-text-light-cream'>
											{child.label}
										</button>
									</p>
								))}
							</div>
						))}
						<div>
							{/* <h6 className='tw-mb-2 tw-flex tw-justify-center tw-font-semibold tw-uppercase md:tw-justify-start'>
								Contact
							</h6> */}
							<div className='tw-mb-2 tw-flex tw-items-center md:tw-justify-end'>
                <div className='tw-w-5 tw-h-5'>
									<AiOutlineWhatsApp
										className='md:tw-h-6 md:tw-w-6 tw-text-white'
										aria-hidden='true'
									/>
								</div>
								<button onClick={() => window.open(app_config.whatsappTemplate)} className='tw-ml-2'>+62 812-8767-2836</button >
							</div>
							<div className='tw-mb-2 tw-flex tw-items-center md:tw-justify-end'>
								<div className='tw-w-5 tw-h-5'>
									<AiOutlineMail
										className='md:tw-h-6 md:tw-w-6 tw-text-white'
										aria-hidden='true'
									/>
								</div>
								<a href='mailto:marketing@socialbread.id' className='tw-ml-2'>marketing@socialbread.id</a>
							</div>
              <div className='tw-flex tw-flex-row tw-gap-4 md:tw-justify-end tw-justify-start tw-my-2'>
                <AiOutlineInstagram
                  className='md:tw-h-8 md:tw-w-8 tw-text-white tw-cursor-pointer'
                  aria-hidden='true'
									onClick={() => window.open(app_config.instagramUrl)}
                />
                <FaTiktok
                  className='md:tw-h-7 md:tw-w-7 tw-text-white tw-cursor-pointer'
                  aria-hidden='true'
									onClick={() => window.open(app_config.tiktokUrl)}
                />
                <AiFillLinkedin
                  className='md:tw-h-8 md:tw-w-8 tw-text-white tw-cursor-pointer'
                  aria-hidden='true'
									onClick={() => window.open(app_config.linkedInUrl)}
                />
              </div>
              <div className='md:tw-flex tw-hidden tw-items-end tw-flex-col tw-gap-2'>
                <Image
                  alt=''
                  src={icon_list.appstore_logo}
                  className='tw-cursor-pointer tw-h-12 tw-w-[150px]'
									onClick={() => window.open(app_config.AppstoreClientAppUrl)}
                />
                <Image
                  alt=''
                  src={icon_list.playstore_logo}
                  className='tw-cursor-pointer tw-h-12 tw-w-[150px]'
									onClick={() => window.open(app_config.AndroidClientAppUrl)}
                />
              </div>
							<div className='tw-w-full tw-justify-start md:tw-hidden tw-flex'>
								<Accordion allowZeroExpanded className='tw-w-full'>
									{menu_list.map((item, index) => {
										return (
											<AccordionItem key={`mobile-item-${index}`} className='p-0'>
												<AccordionItemHeading className='tw-mb-0'>
													<AccordionItemButton className='tw-flex tw-justify-between tw-pt-4 tw-text-white tw-items-center'>
														<div className='tw-font-semibold'>{item.name}</div>
														<AccordionItemState>
															{({ expanded }) => ( <AiOutlineRight className={expanded ? 'tw-rotate-[-90deg]' : 'tw-rotate-[90deg]'} width={20} aria-hidden='true'/> )}
														</AccordionItemState>
													</AccordionItemButton>
												</AccordionItemHeading>
												<AccordionItemPanel className='tw-p-2 tw-text-white tw-mt-0'>
													{item.child.map((child, index) => {
														return (
															<button key={`child-m-menu-${index}`} className="tw-w-full tw-text-start hover:tw-bg-green-700 tw-rounded-md">
																<a
																	href={child.href}
																	className="tw-block tw-pl-2 tw-py-2 tw-text-sm tw-text-md"
																>
																	{child.label}
																</a>
															</button>
														)
													})}
												</AccordionItemPanel>
											</AccordionItem>
										)
									})}
								</Accordion>
							</div>
						</div>
					</div>
				</div>
				<div className='tw-py-4 tw-text-white tw-text-sm tw-text-center tw-mt-10'>
					<p>All Right Reserved 2024 © PT. Sosial Berkat Kreatif Indonesia</p>
				</div>
			</div>
		</footer>
	)
}

export default Footer

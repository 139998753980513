// @ts-nocheck
import StyledButton from "../button"

const BlogPagination = ({infoPage, setBlogList}) => {
  const currentPage = Number(infoPage.currentPage)
  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-4">
      <button onClick={() => setBlogList({page: currentPage - 1, limit: 10, order: 'desc'})} className="tw-text-black tw-font-semibold disabled:tw-text-gray-400" disabled={currentPage === 1}>
        {/* <ArrowLeftIcon strokeWidth={2} className="h-4 w-4" /> Previous */}
        Prev
      </button>
      <div className="tw-flex tw-items-center tw-gap-2 tw-font-semibold">
        {Array(infoPage.totalPage).fill(0).map((item, index) => {
          const number = index + 1
          return (
           <button key={`button-pagination-${index}`} className={`tw-mt-0 ${currentPage === number && 'tw-text-light-green'}`} onClick={() => setBlogList({page:number, limit: 10, order: 'desc'})}>{number}</button>
          )
        })}
      </div>
      <button onClick={() => setBlogList({page: currentPage + 1, limit: 10, order: 'desc'})} className="tw-text-black tw-font-semibold disabled:tw-text-gray-400" disabled={currentPage === infoPage.totalPage}>
        Next
        {/* <ArrowRightIcon strokeWidth={2} className="h-4 w-4" /> */}
      </button>
    </div>
  )
}


export default BlogPagination
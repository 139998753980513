import { getCookie } from "./cookies";

const getUserDataRawLS = () => {
  const userData:any = getCookie('user-data-raw');
  return JSON.parse(userData);
}

const getUserDetailData = () => {
  const userData:any = getCookie('user-data-detail');
  return JSON.parse(userData);
}

const getLanguage = () => {
  const language:any = getCookie('language');
  return language;
}

export { getUserDataRawLS, getUserDetailData, getLanguage }
import React, { useState, useRef, ChangeEvent, KeyboardEvent } from 'react';

type OtpType = {
  onInput: (data:any) => void,
}

const OtpInput = ({onInput}:OtpType) => {
  const [otp, setOtp] = useState<string[]>(['', '', '', '', '', '']);
  const inputRefs = [useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null), useRef<HTMLInputElement>(null)];

  const handleChange = (index: number, value: string) => {
    if (!isNaN(Number(value))) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      onInput(newOtp);
      
      if (index < inputRefs.length - 1 && value !== '') {
        inputRefs[index + 1].current?.focus();
      }
    }
  };

  const handleKeyDown = (index: number, event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Backspace' && otp[index] === '' && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      onInput(newOtp);
      inputRefs[index - 1].current?.focus();
    }
  };

  return (
    <div className="tw-flex tw-justify-center tw-items-center">
      <div className="tw-space-x-4">
        {otp.map((value, index) => (
          <input
            key={index}
            ref={inputRefs[index]}
            type="text"
            maxLength={1}
            className="md:tw-w-12 md:tw-h-12 tw-w-10 tw-h-10 tw-text-3xl tw-text-center tw-border tw-rounded focus:tw-outline-none tw-border-light-green"
            value={value}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(index, e.target.value)}
            onKeyDown={(e: KeyboardEvent<HTMLInputElement>) => handleKeyDown(index, e)}
          />
        ))}
      </div>
    </div>
  );
};

export default OtpInput;

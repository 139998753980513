/* eslint-disable @next/next/no-img-element */
// @ts-nocheck
import { Swiper, SwiperSlide } from 'swiper/react'
import { background_list, icon_list } from "@/assets/images"
import { useRef, useEffect, useState } from 'react'
import Image from "next/image"
import 'swiper/css';
import 'swiper/css/navigation';
import StyledButton from '../button';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import route_list from '@/constant/routeUrl';

const CardLatestNews = ({data}:any) => {
	const { t } = useTranslation('blog')
  const swiperElRef = useRef<any>(null)
  const [activIndex, setActiveIndex] = useState(0)
  const [totalSlide, setTotalSlide] = useState([])
  const router = useRouter()

  // console.log(swiperElRef.current.swiper)
  // swiperElRef.current.swiper.slides

  // useEffect(() => {
  //   console.log(swiperElRef)
  //   if(!_.isEmpty(swiperElRef.current.swiper)) {
  //     setActiveIndex(swiperElRef.current.swiper.activeIndex)
  //     setTotalSlide(swiperElRef.current.swiper.slides)
  //   }
  // }, [swiperElRef])

  const handleSlideChange = () => {
    setActiveIndex(swiperElRef.current.swiper.activeIndex)
  }
  // console.log(totalSlide)
  // if(_.isEmpty(swiperElRef.current)) return <div></div>

  useEffect(() => {
    if(!_.isEmpty(swiperElRef.current.swiper)) {
      setActiveIndex(swiperElRef.current.swiper.activeIndex)
      setTotalSlide(swiperElRef.current.swiper.slides)
    }
  }, [data])

  return (
    <Swiper
      className='md:tw-max-w-full tw-max-w-sm'
      ref={swiperElRef}
      spaceBetween={50}
      onSlideChange={handleSlideChange}
      // onSwiper={(swiper) => console.log(swiper)}
      slidesPerView={1}
      autoplay
    >
      {data.map((item:any, index:number) => {
        return (
          <SwiperSlide key={`featured-${index}`}>
            <div className="tw-mb-12 tw-flex md:tw-flex-row tw-flex-col tw-rounded-xl">
              <div className="tw-relative tw-overflow-hidden tw-rounded-lg  tw-bg-cover tw-bg-no-repeat md:tw-w-[50%]">
                <img src={item.img} className="tw-w-full tw-h-[250px] tw-object-cover" alt="" />
              </div>
              <div className="md:tw-mb-6 tw-mb-2 tw-mr-auto  md:tw-pl-7 md:tw-p-4 tw-py-4 md:tw-w-[50%] tw-flex tw-flex-col tw-justify-center">
                <span className="tw-text-red-600 tw-font-semibold tw-text-md tw-mb-3">Latest News</span>
                <h5 className="tw-mb-3 md:tw-text-2xl tw-text-lg tw-font-bold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-30% tw-to-light-green tw-to-70% tw-text-left">{item.title}</h5>
                <p className="tw-text-neutral-500 tw-text-sm">
                  {item.description}
                </p>
                <div className='tw-flex tw-flex-row tw-items-center tw-gap-3'>
                  <p className="tw-text-neutral-500">
                    <small>{item.date}</small>
                  </p>
                  <h1 className=' tw-text-md tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-30% tw-to-light-green tw-to-70% tw-text-sm tw-font-semibold'>By {item.author}</h1>
                </div>
                <div className='tw-flex tw-justify-between tw-items-center tw-mt-2'>
                  <div className="tw-flex tw-gap-2 tw-items-center tw-text-sm tw-font-medium tw-text-danger tw-justify-start">
                    <div className='tw-bg-gray-200 tw-px-2 tw-py-1 tw-text-dark-green'>{item.type}</div>
                  </div>
                  <StyledButton
                    title={t('blog.read_more')}
                    className='btn-dark-to-light-green-gradient tw-text-white tw-font-semibold md:tw-text-md tw-text-xs tw-items-center  tw-px-5 md:tw-py-2 tw-py-1 tw-rounded-full md:tw-flex '
                    onClick={() => router.push(route_list.blogDetail(item.slug))}
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        )
      })}
      
      <div className='tw-z-10 tw-w-[100px] tw-absolute tw-left-[0px] tw-bottom-[20px] tw-flex tw-flex-row tw-justify-between'>
        <div className='tw-flex tw-flex-row tw-gap-3'>
          {totalSlide.map((item:any, index:number) => {
            const currentSlice = swiperElRef.current.swiper.slides[index]
            return (
              <button className={`${activIndex === index ? 'tw-bg-light-green' : 'tw-bg-gray-300'} tw-w-4 tw-h-4 tw-rounded-full `} key={`bullet-${index}`} onClick={() => console.log(swiperElRef.current.swiper.slideTo(index))}></button>
            )
          })}
        </div>
        {/* <button onClick={() => swiperElRef.current.swiper.slidePrev()}>
          <Image
            alt=''
            src={icon_list.prev_swiper_say}
            className='tw-cursor-pointer tw-h-12 '
          />
        </button>
        <button onClick={() => swiperElRef.current.swiper.slideNext()}>
          <Image
            alt=''
            src={icon_list.next_swiper_say}
            className='tw-cursor-pointer tw-h-12 '
          />
        </button> */}
      </div>
    </Swiper>
  )
}

export default CardLatestNews
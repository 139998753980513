import { validatePhoneNumber } from '@/utility/helper/dataMutation';
import { useEffect, useState } from 'react';

const countries = [
  { code: '+62', name: 'Indonesia', flag: '🇺🇸' },
  // { code: '+1', name: 'USA', flag: '🇺🇸' },
  // { code: '+44', name: 'UK', flag: '🇬🇧' },
  // { code: '+61', name: 'Australia', flag: '🇦🇺' },
  // { code: '+33', name: 'France', flag: '🇫🇷' },
  // { code: '+49', name: 'Germany', flag: '🇩🇪' },
  // { code: '+81', name: 'Japan', flag: '🇯🇵' },
  // { code: '+86', name: 'China', flag: '🇨🇳' },
];

interface PhoneNumberProps {
  // countries: { code: string; name: string }[];
}

const PhoneNumberInput: React.FC<PhoneNumberProps> = ({ className, onChange, data }:any) => {  
  const [selectedCountry, setSelectedCountry] = useState(countries[0].code);
  const [phoneNumber, setPhoneNumber] = useState(data.value);

  const handleCountryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedCountry(event.target.value);
    onChange({
      target: {
        type: 'phone',
        value: event.target.value + phoneNumber
      }
    })
  };

  const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const phone = validatePhoneNumber(event.target.value)
    setPhoneNumber(phone);
    onChange({
      target: {
        type: 'phone',
        value: selectedCountry + phone,
        name: data.name
      }
    })
  };

  useEffect(() => {
    setPhoneNumber(data.value)
  }, [data])

  return (
    <div className={`tw-flex tw-items-center tw-border tw-rounded-2xl tw-px-2 tw-py-1 tw-border-light-green ${data.disabled ? ' tw-bg-[#d1d1d16b]' : 'tw-bg-white'} tw-overflow-hidden ${className}`}>
      <select
        className="tw-px-2 tw-py-1 tw-outline-none tw-bg-transparent"
        value={selectedCountry}
        onChange={handleCountryChange}
        disabled={data.disabled}
      >
        {countries.map(country => (
          <option key={country.code} value={country.code}>
            {country.code}
          </option>
        ))}
      </select>
      <input
        type="text"
        className="tw-px-2 tw-py-1 tw-outline-none tw-border-l tw-ml-1"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        disabled={data.disabled}
      />
    </div>
  );
};

export default PhoneNumberInput;

PhoneNumberInput.defaultProps = {
  data: {
    className: '',
  },
  onChange: () => {},
};
// @ts-nocheck
import Image from "next/image"

/* eslint-disable @next/next/no-img-element */
const CardLiveShopWhatis = ({data}:any) => {
  return (
    <div className='tw-grid md:tw-grid-cols-2 tw-gap-9'>
      <div className='tw-w-full tw-flex md:tw-flex-col tw-gap-9 md:tw-bg-transparent tw-bg-white tw-rounded-xl tw-overflow-hidden tw-flex-col-reverse'>
        <div className="tw-w-full tw-mb-0 md:tw-p-0 tw-p-5">
          <Image src={data[0].image} alt="" className="tw-relative tw-z-40 tw-object-cover tw-w-full tw-h-full tw-rounded-xl" />
        </div>
        <div className="tw-w-full md:tw-p-10 tw-px-5 tw-pt-5 tw-mb-0 md:tw-bg-white tw-rounded-xl">
          <h2 className="tw-mb-4 md:tw-text-4xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-w-fit tw-text-left">
            {data[1].title}
          </h2>
            <p className="tw-text-base tw-leading-7 tw-black tw-text-justify" dangerouslySetInnerHTML={{__html: data[1].description}}></p>
        </div>
      </div>
      <div className='tw-w-full tw-flex tw-flex-col tw-gap-9 md:tw-bg-transparent tw-bg-white tw-rounded-xl tw-overflow-hidden md:tw-p-0 tw-p-5 tw-h-full'>
        <div className="tw-w-full md:tw-p-10 tw-mb-0 md:tw-bg-white tw-rounded-xl">
          <h2 className="tw-mb-4 md:tw-text-4xl tw-text-2xl md:tw-max-w-[250px] tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-to-light-green tw-w-fit tw-text-left">
           {data[0].title}
          </h2>
          <p className="tw-text-base tw-leading-7 tw-text-justify tw-black" dangerouslySetInnerHTML={{__html: data[0].description}}></p>
        </div>
        <div className='tw-h-full tw-flex tw-flex-row md:tw-gap-9 tw-gap-5'>
          <div className="tw-w-full tw-mb-0 md:tw-p-0">
            <Image src={data[1].image1} alt="" className="tw-relative tw-z-40 tw-object-cover tw-w-full tw-h-full tw-rounded-xl" />
          </div>
          <div className="tw-w-full tw-mb-0 md:tw-p-0">
            <Image src={data[1].image2} alt="" className="tw-relative tw-z-40 tw-object-cover tw-w-full tw-h-full tw-rounded-xl" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardLiveShopWhatis
export { default as axis_logo } from './axisLogo.png';
export { default as indosat_logo } from './indosatLogo.png';
export { default as appstore_logo } from './appstore.svg';
export { default as playstore_logo } from './playstore.svg';
export { default as default_profile } from './default-profile.svg';
export { default as cheveron_bottom_gray } from "./cheveron-bottom-gray.svg"
export { default as chevron_down_gray } from './cheveron-down-gray.svg';
export { default as search_gray } from './search-gray.svg';
export { default as arrow_rotate } from './arrow-rotate.svg';
export { default as delete_product } from './delete-product.svg';
export { default as copy_green } from './copy-green.svg';
export { default as idntimes } from './idntimes.png';
export { default as investor } from './investor.png';
export { default as jawa_pos } from './jawa-pos.png';
export { default as sinar_harapan } from './sinar-harapan.png';
export { default as next_swiper_say } from './next-swiper-say.svg';
export { default as prev_swiper_say } from './prev-swiper-say.svg';
export { default as next_gradient_green } from './next-swiper.svg';
export { default as prev_gradient_green } from './prev-swiper.svg';
export { default as bag_green } from './bag-green.svg';
export { default as people_green } from './people-green.svg';
export { default as contract_green } from './contract-green.svg';
export { default as calendar_green } from './calendar-green.svg'; 
export { default as tiktok_logo } from './tiktok-logo.png'; 
export { default as tiktok_partner } from './tiktok-partnr.png'; 
export { default as shopee_logo } from './shope-logo.png'; 
export { default as shopee_partner } from './shop-partnr.png'; 
export { default as share_icon } from './share.png'; 
export { default as location_icon } from './location.png'; 
export { default as career_file } from './carrer-file.svg'; 
export { default as career_remove } from './carrer-remove.svg'; 
export { default as notif_icon } from './notif.png'; 
export { default as socialbread_logo } from './socialbreadlogo.jpg'; 

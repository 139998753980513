// @ts-nocheck
import { Swiper, SwiperSlide } from 'swiper/react'
import { useRef, useEffect, useState } from 'react'
import { Navigation } from 'swiper/modules'
import Image from 'next/image'
import StyledButton from '../button'
import { background_list } from '@/assets/images'
import 'swiper/css';
import 'swiper/css/navigation';


const PackageSwiperBig = ({data, cardClass, titleClass, devide}:any) => {
	const swiperElRef = useRef<any>(null)
  const [hoverIndex, setHoverIndex] = useState<number>(0)

	return (
		<Swiper
			className='md:tw-max-w-full tw-max-w-xs tw-px-[20px]'
			ref={swiperElRef}
			navigation
			modules={[Navigation]}
			spaceBetween={50}
			onSlideChange={() => console.log('slide change')}
			// onSwiper={(swiper) => console.log(swiper)}
			slidesPerView={1}
			breakpoints={{
				500: {
					slidesPerView: 1,
					spaceBetween: 20,
				},
				1024: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
			}}
		>
      {data.map((item:any, index:number) => {
        const devideByTwo = index % 2 === 0
        const splitedTitle = item.title.split(' ')


        return (
          <SwiperSlide key={`swiper-${index}`}>
            <div className='md:tw-px-5 tw-w-full'>
              <div onMouseLeave={() => setHoverIndex(0)} onMouseEnter={() => setHoverIndex(index+1)} className={`tw-rounded-[30px] tw-flex tw-flex-col tw-items-center tw-justify-center tw-relative tw-overflow-hidden tw-border-2 tw-border-dark-green ${!devideByTwo && ''} ${cardClass}`}>
                <Image src={ devideByTwo ? background_list.card_wave_green_little : background_list.card_wave_gray_little} alt="arrow rotate" className={`tw-absolute tw-top-0 tw-right-0 tw-scale-[1.1] tw-z-0 tw-h-full ${!devideByTwo && ' tw-grayscale'}`} />
                <div className={`tw-w-full tw-h-full tw-flex tw-flex-col tw-items-center tw-z-10 ${hoverIndex === index+1 && 'tw-hidden'}`}>
                  {devide === false 
                  ? (
                    <>
                      {devideByTwo ? (
                          <h1 className={`md:tw-text-3xl tw-text-2xl tw-font-semibold tw-text-white  tw-mt-5 tw-mb-3 max-w ${titleClass}`}>{item.title}</h1>
                        ) : (
                          <h1 className={`md:tw-text-3xl tw-text-2xl tw-font-semibold  tw-mt-5 tw-mb-3 max-w  tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% ${titleClass}`}>{item.title}</h1>
                        )}</>
                  ) : (
                    <div className=''>
                      {devideByTwo ? (
                        <h1 className={`md:tw-text-3xl tw-text-2xl tw-font-semibold tw-text-white  tw-mt-5 tw-mb-3 max-w ${titleClass}`}>
                          {splitedTitle[0]} <br /> {splitedTitle.map((item:any, index:number) => index !== 0 && `${item} `)} 
                        </h1>
                      ) : (
                        <h1 className={`md:tw-text-3xl tw-text-2xl tw-font-semibold tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-z-10 tw-mt-5 tw-mb-3 ${titleClass}`}>
                          {splitedTitle[0]} <br /> {splitedTitle.map((item:any, index:number) => index !== 0 && `${item} `)}
                        </h1>
                      )}
                    </div>
                  )}
                  
                  {item.image && (
                    <Image src={item.image} alt='' className='tw-z-10 tw-h-[200px] tw-object-contain' />
                  )}
                </div>
 
                <div className={`tw-z-[1] ${hoverIndex !== index+1 && 'tw-hidden'}`}>
                  {devideByTwo ? (
                    <div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-z-10 tw-h-[300px]'>
                      <ol className=' tw-text-white tw-list-disc md:tw-text-[16px] md:tw-px-[70px] tw-px-[40px] tw-text-sm tw-pt-9 tw-text-left tw-gap-3 tw-flex tw-flex-col tw-flex-1'>
                        {item.description.map((item, index) => (
                          <li key={`smm-${index}`}>{item}</li>
                        ))}
                      </ol>
                      <div className=' tw-text-2xl tw-font-semibold tw-text-white tw-mt-3'>{item.price}</div>
                    </div>
                  ) : (
                    <div className='tw-w-full tw-flex tw-flex-col tw-items-center tw-z-10 tw-h-[300px]'>
                      <ol className=' tw-text-dark-green tw-list-disc md:tw-text-[16px] md:tw-px-[70px] tw-px-[40px] tw-text-sm tw-pt-9 tw-text-left tw-gap-3 tw-flex tw-flex-col tw-flex-1'>
                        {item.description.map((item, index) => (
                          <li key={`smm-${index}`}>{item}</li>
                        ))}
                      </ol>
                      <div className='tw-text-transparent tw-bg-clip-text tw-bg-gradient-to-r tw-from-dark-green tw-from-40% tw-to-light-green tw-to-60% tw-font-semibold tw-text-2xl tw-mt-3'>{item.price}</div>
                    </div>
                  )}
                </div>
                
                <StyledButton
                  title={item.buttonLabel}
                  className={`${devideByTwo ? 'tw-text-white tw-border-white': 'tw-text-dark-green tw-border-dark-green'} tw-mb-10 tw-font-semibold tw-z-10 md:tw-text-xl tw-text-xs tw-items-center tw-border-2 md:tw-px-6 tw-px-3 md:tw-py-2 tw-py-1 tw-rounded-full tw-justify-center md:tw-flex tw-mt-5`}
                  onClick={item.onClick}
                />
              </div>
            </div>
          </SwiperSlide>
        )
      })}
		</Swiper>
	)
}

export default PackageSwiperBig

// @ts-nocheck
import { Swiper, SwiperSlide } from 'swiper/react'
import { background_list, icon_list } from "@/assets/images"
import { useRef, useEffect, useState } from 'react'
import Image from "next/image"
import 'swiper/css';
import 'swiper/css/navigation';

const CardWhatTheySay = ({data}:any) => {
  const swiperElRef = useRef<any>(null)
  const [prevDisabled, setPrevDisabled] = useState<boolean>(true)
  const [nextDisabled, setNextDisabled] = useState<boolean>(false)

  return (
    <div className='tw-rounded-xl tw-relative bg-green-yellow md:tw-p-12 tw-p-4'>
      <Swiper
        className='md:tw-max-w-full tw-max-w-xs'
        ref={swiperElRef}
        spaceBetween={50}
        onSlideChange={() => console.log('slide change')}
        // onSwiper={(swiper) => console.log(swiper)}
        slidesPerView={1}
      >
        {data.map((item:any, index:number) => {
          return (
            <SwiperSlide key={`tcard-${index}`}>
                <div className="md:tw-text-4xl tw-text-lg tw-font-semibold tw-text-white md:tw-text-justify tw-text-left">
                  {item.description}
                </div>
                <div className='tw-flex tw-flex-row tw-mt-10 tw-gap-3 tw-mb-[150px]'>
                  <div className='tw-h-[50px] md:tw-w-[50px] tw-w-[50px]  tw-rounded-full tw-overflow-auto'>
                    <Image
                        alt=''
                        src={item.photo}
                        className='tw-cursor-pointer tw-h-full tw-w-full tw-object-cover'
                      />
                  </div>
                  <div className='tw-flex tw-flex-col'>
                    <span className='tw-text-white tw-text-base'>{item.name}</span>
                    <span className='tw-text-white tw-text-md tw-font-semibold tw-text-left'>{item.position}</span>
                  </div>
                </div>
            </SwiperSlide>
          )
        })}
        <div className='tw-z-10 tw-w-[100px] tw-absolute tw-right-[10px] tw-bottom-[30px] tw-flex tw-flex-row tw-justify-between'>
          <button aria-label="btn-next-a" id='btn-next-a' name='btn-next-a' onClick={() => {
              swiperElRef.current.swiper.slidePrev()
              if(swiperElRef.current?.swiper.activeIndex === 0) {
                setPrevDisabled(true)
                setNextDisabled(false)
              } else {
                setPrevDisabled(false)
              }
            }} className={`${prevDisabled && 'tw-opacity-50'}`}>
            <Image
              alt=''
              src={icon_list.prev_swiper_say}
              className='tw-cursor-pointer tw-h-12 '
            />
          </button>
          <button aria-label="btn-next-b" id='btn-next-b' name='btn-next-b' onClick={() => {
            swiperElRef.current.swiper.slideNext()
            if(swiperElRef.current?.swiper.activeIndex === data.length-1) {
              setNextDisabled(true)
              setPrevDisabled(false)
            } else {
              setNextDisabled(false)
            }
          }} className={`${nextDisabled ? 'tw-opacity-50' : ""}`}>
            <Image
              alt=''
              src={icon_list.next_swiper_say}
              className='tw-cursor-pointer tw-h-12 '
            />
          </button>
        </div>
      </Swiper>
    </div>
  )
}

export default CardWhatTheySay
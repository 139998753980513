export { default as sosbread_white_logo } from './sosbread-white-logo.svg';
export { default as register_phone } from './register-phone.webp';
export { default as card_wave_green } from './card-wave-bg-green.webp';
export { default as card_wave_green_little } from './card-wave-gren-little.webp';
export { default as card_wave_green_little_m } from './card-green-more-little.webp';
export { default as card_wave_green_big } from './card-wave-gren-big.webp';
export { default as card_wave_gray } from './card-wave-bg-gray.webp';
export { default as card_wave_gray_little } from './card-wave-gray-little.webp';
export { default as card_wave_gray_little_m } from './card-gray-more-little.webp';
export { default as pending_payment } from './pending-payment.webp';
export { default as success_payment } from './success-payment.webp';
export { default as expired_payment } from './expired-payment.webp';
export { default as homepage_icon } from './homepage-phone.webp';
export { default as sila } from './sila.webp';
export { default as sila2 } from './sila2.webp';
export { default as sila3 } from './sila3.webp';
export { default as sila4 } from './sila4.webp';
export { default as sila7 } from './sila7.webp';
export { default as DSelfie } from './d-selfie.webp';
export { default as smm_head } from './smm-head.webp';
export { default as live_shopping_head } from './live-shopping-head.webp';
export { default as endorsement } from './endorsement.webp';
export { default as content_creator } from './content-creator.webp';
export { default as gradient_blue } from './gradient-blue.webp';
export { default as gradient_blue_reverse } from './gradient-blue-reverse.webp';
export { default as want_tobe_creator } from './want-tobe-creator.webp';
export { default as live_streamer } from './live-streamer.webp';
export { default as how_to_apply } from './how-to-apply.webp';
export { default as influencer } from './influencer.webp';
export { default as how_to_influencer } from './how-to-influencer.webp';
export { default as gradient_purple } from './gradient-purple.webp';
export { default as gradient_purple_reverse } from './gradient-purple-reverse.webp';
export { default as gradient_green } from './gradient-green.webp';
export { default as gradient_green_reverse } from './gradient-green-reverse.webp';
export { default as wave_upper } from './wave-upper.svg';
export { default as endorse_selfie } from './endors-selfie.webp';
export { default as vicky } from './vicky.webp';
export { default as raga } from './raga.webp';
export { default as why_we_1 } from './siapa-kami-1.webp';
export { default as why_we_2 } from './siapa-kami-2.webp';
export { default as why_social_bread_hp } from './why-social-bread-hp.webp';
export { default as gradient_centered_orange } from './gradient-centered-orange.webp';
export { default as how_are_we } from './how-are-we.webp';
export { default as what_is_img_1 } from './what-is-img-1.webp';
export { default as what_is_img_2 } from './what-is-img-2.webp';
export { default as liveshop1 } from './liveshop1.webp';
export { default as liveshop2 } from './liveshop2.webp';
export { default as liveshop3 } from './liveshop3.webp';
export { default as liveshop4 } from './liveshop4.webp';
export { default as studio1 } from './studio1.webp';
export { default as studio2 } from './studio2.webp';
export { default as studio3 } from './studio3.webp';
export { default as studio4 } from './studio4.webp';
export { default as studio5 } from './studio5.webp';
export { default as studio6 } from './studio6.webp';
export { default as studio7 } from './studio7.webp';
export { default as studio8 } from './studio8.webp';
export { default as endorsementbg } from './endorsementbg.webp';
export { default as endorsementbgtop } from './endorsement-top.webp';
export { default as cc1 } from './cc1.webp';
export { default as cc2 } from './cc2.webp';
export { default as cc3 } from './cc3.webp';
export { default as ls1 } from './ls1.webp';
export { default as ls2 } from './ls2.webp';
export { default as ls3 } from './ls3.webp';
export { default as if1 } from './if1.webp';
export { default as if2 } from './if2.webp';
export { default as if3 } from './if3.webp';
export { default as our1 } from './our1.webp';
export { default as our2 } from './our2.webp';
export { default as our3 } from './our3.webp';
export { default as ot1 } from './ot1.webp';
export { default as ot2 } from './ot2.webp';
export { default as ot3 } from './ot3.webp';
export { default as ot4 } from './ot4.webp';
export { default as ott1 } from './ott1.webp';
export { default as ott2 } from './ott2.webp';
export { default as ott3 } from './ott3.webp';
export { default as ott4 } from './ott4.webp';
export { default as ott5 } from './ott5.webp';
export { default as ott6 } from './ott6.webp';
export { default as ott7 } from './ott7.webp';
export { default as ott8 } from './ott8.webp';
export { default as ott9 } from './ott9.webp';
export { default as ott10 } from './ott10.webp';
export { default as ott11 } from './ott11.webp';
export { default as ott12 } from './ott12.webp';
export { default as ott13 } from './ott13.webp';
export { default as ott14 } from './ott14.webp';
export { default as ott15 } from './ott15.webp';
export { default as ott16 } from './ott16.webp';
export { default as ott17 } from './ott17.webp';
export { default as ott18 } from './ott18.webp';
export { default as ott19 } from './ott19.webp';
export { default as ott20 } from './ott20.webp';
export { default as ott21 } from './ott21.webp';
export { default as ott22 } from './ott22.webp';
